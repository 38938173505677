import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PaymentCard from 'pages/ContributionView/components/PaymentCard';
import './EasyBooking.scss';
import { getOnToOnePaymentInfo } from 'services/purchase.service';
import * as contributionActions from 'actions/contributions';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useDispatch, useSelector } from 'react-redux';
import useRouter from 'hooks/useRouter';
import BookingSummary from './components/BookingSummary';
import { setEasyBookingData } from 'actions/easyBooking';
import { determineColorToUse } from 'services/contributions.service';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { message } from 'antd';
import BookSingleSession from 'pages/ContributionView/components/PurchaseModal/CalendarBookSession';
import { lightOrDark } from 'utils/utils';

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const EasyBooking = ({
  clientPreviewMode = false,
  isSelfSchedule = false,
  rescheduleSession = false,
  setRescheduleSession,
  isModal = false,
  packageSessionBooking = false,
  setShowModal = () => {},
  setRescheduleBookingmodal = () => {},
  isCoach = false,
  isMasterCalendar = false,
}) => {
  const activeContribution = useContribution();
  const { AccentColorCode = '', PrimaryColorCode = '' } = determineColorToUse(
    isMasterCalendar ? null : activeContribution,
  );
  const colorToUse = determineColorToUse(activeContribution);
  const { serviceProviderName = '', durations, coachAvatarUrl = '', isDarkModeEnabled = false } = activeContribution;
  const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const formerBookingData = useSelector(state => state?.contributions.formerBookingData);
  const availableToReschedule = moment(
    easyBookingThankYou?.selectedSlot?.startTime || formerBookingData.selectedSlot?.startTime,
  ).isAfter(moment().add(24, 'h'));
  const duration = durations[0];
  const dispatch = useDispatch();
  const {
    match: {
      params: { code },
    },
  } = useRouter();
  const easyBookingData = useSelector(state => state?.easyBooking);

  useEffect(() => {
    dispatch(setEasyBookingData.setData({ ...easyBookingData, code }));
  }, [code]);

  return (
    <div className={`easy-booking-page ${isDarkModeEnabled ? 'cohere-dark-mode' : ''}`}>
      {rescheduleSession
        ? isModal === false && (
            <div className="easy-booking-header" style={{ backgroundColor: AccentColorCode, color: textColor }}>
              Reschedule your session below.
            </div>
          )
        : isModal === false && (
            <div
              className={isModal ? 'easy-booking-header-modal' : 'easy-booking-header'}
              style={{ backgroundColor: AccentColorCode, color: textColor }}
            >
              Book your next session below.
            </div>
          )}
      <div className={isModal ? 'easy-booking-container-modal' : 'easy-booking-container'}>
        {(easyBookingThankYou?.selectedSlot?.startTime != undefined ||
          formerBookingData.selectedSlot?.startTime != undefined) &&
          !availableToReschedule &&
          isCoach === false && (
            <Notification
              notification={{
                heading: 'Unable to Reschedule',
                message: 'Cannot reschedule within 24 hours of the session start time.',
              }}
              component={true}
            />
          )}
        <BookingSummary
          coachAvatarUrl={coachAvatarUrl}
          serviceProviderName={serviceProviderName}
          duration={duration}
          contributionTitle={activeContribution?.title}
          purpose={activeContribution?.purpose}
          viewPurpose={activeContribution?.viewPurpose}
          accentColorCode={AccentColorCode}
          primaryColorCode={PrimaryColorCode}
          clientPreviewMode={clientPreviewMode}
          rescheduleSession={rescheduleSession}
          setRescheduleSession={setRescheduleSession}
          isModal={isModal}
        />
        <div className={'calendar-side'}>
          {isCoach ? (
            <BookSingleSession
              isCoach={isCoach}
              isModal={isModal}
              contribution={activeContribution}
              type={activeContribution?.type}
              isCustomBrandingColorsActive={false}
              easyBooking={true}
              clientPreviewMode={clientPreviewMode}
              isSelfSchedule={isSelfSchedule}
              rescheduleSession={rescheduleSession}
              setRescheduleSession={setRescheduleSession}
              packageSessionBooking={packageSessionBooking}
              setShowModal={setShowModal}
              setRescheduleBookingmodal={setRescheduleBookingmodal}
            />
          ) : (
            <PaymentCard
              contribution={activeContribution}
              type={activeContribution?.type}
              isCustomBrandingColorsActive={false}
              easyBooking={true}
              clientPreviewMode={clientPreviewMode}
              isSelfSchedule={isSelfSchedule}
              rescheduleSession={rescheduleSession}
              setRescheduleSession={setRescheduleSession}
              packageSessionBooking={packageSessionBooking}
              setShowModal={setShowModal}
              setRescheduleBookingmodal={setRescheduleBookingmodal}
              isModal={isModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EasyBooking;
