import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { LabelText } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles/styles';
import 'styles/creditCard.scss';
import { TextField, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { useStyles } from '../PurchaseBlock/hooks/useStyles';
import { TemplateType } from 'helpers/constants/templateType';
import { lightOrDark } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const ApplyCouponContainer = styled.div`
  background-color: #fafafa;
  padding: 5px 15px;
  // margin-top: 20px;
`;

export const StyledInput = styled.input`
  padding: 6px 11px 10px 11px;
  border: 0;
  border-bottom: 1px solid #b3b3b3;
  outline: none;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    border-color: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #ff4d4f;
    `}
`;

const StyledLink = styled.a`
  width: 100%;
  box-sizing: border-box;
  color: ${colors.darkOceanBlue};
  font-size: 12px;
  font-weight: 600;
`;

function CustomApplyCoupon({
  handleChange,
  activeTemplate,
  colorToUse,
  values,
  touched,
  errors,
  handleRedeem,
  setCoupon,
  couponField = false,
  padding,
}) {
  const classNames = useStyles();
  const radeemClass =
    activeTemplate === TemplateType.TemplateOne
      ? classNames.redeemButtonTemp1
      : activeTemplate === TemplateType.TemplateTwo
      ? classNames.redeemButtonTemp2
      : classNames.redeemButton;
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const contribution = useContribution();
  const { themedBackgroundColor } = getThemedColors(contribution);
  let textColor =
    activeContribution?.coachSelectedBrandingColors?.TextColorCode === 'Auto'
      ? lightOrDark(activeContribution?.coachSelectedBrandingColors?.PrimaryColorCode)
      : activeContribution?.coachSelectedBrandingColors?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return (
    <>
      <ApplyCouponContainer style={{ backgroundColor: themedBackgroundColor, padding: padding ? padding : '' }}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          wrap="nowrap"
          style={{ backgroundColor: 'white', borderRadius: '12px', border: '1px solid #f5f0f0' }}
        >
          {couponField ? (
            <Grid container md={9} xs={9}>
              <TextField
                fullWidth
                className={classNames.discountCodeTextFied}
                name="Coupon"
                placeholder="Discount Code"
                type="text"
                onChange={e => {
                  setCoupon(e.target.value);
                }}
                variant="outlined"
                value={values?.Coupon}
                InputProps={{
                  classes: { notchedOutline: classNames.noBorder },
                }}
              />
            </Grid>
          ) : (
            <Grid container md={9} xs={9}>
              <TextField
                fullWidth
                className={classNames.discountCodeTextFied}
                name="Coupon"
                placeholder="Discount Code"
                type="text"
                onChange={handleChange('Coupon')}
                variant="outlined"
                value={values?.Coupon}
                InputProps={{
                  classes: { notchedOutline: classNames.noBorder },
                }}
              />
            </Grid>
          )}
          <Grid container justify="flex-end" md={2} xs={2}>
            <Button
              style={
                activeTemplate === TemplateType.TemplateTwo
                  ? {
                      backgroundColor: activeContribution?.coachSelectedBrandingColors?.PrimaryColorCode,
                      color: textColor,
                      marginRight: '10px',
                    }
                  : {
                      color: activeContribution?.coachSelectedBrandingColors?.PrimaryColorCode,
                      marginRight: '10px',
                    }
              }
              className={radeemClass}
              onClick={e => {
                e.preventDefault();
                handleRedeem();
              }}
              size={downSm ? 'medium' : 'large'}
              variant="text"
            >
              Redeem
            </Button>
          </Grid>
        </Grid>
      </ApplyCouponContainer>
    </>
  );
}

CustomApplyCoupon.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleRedeem: PropTypes.func.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default CustomApplyCoupon;
