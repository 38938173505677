import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import usePaidTier from 'hooks/usePaidTier';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import VideoButton from 'components/UI/ListButtons/VideoButton';
import EditButton from 'components/UI/ListButtons/EditButton';
import { useAccount } from 'hooks/index';
import './Card.scss';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType, getClientContribution, getCohealerContribution } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../constants';
const types = {
  ContributionCourse: 'Live Course',
  ContributionOneToOne: '1:1',
  ContributionMembership: 'Membership',
  ContributionCommunity: 'Community',
};

const CardContainer = ({
  children,
  isSession,
  isSales,
  id,
  moreLink,
  title,
  subTitle,
  sessionId,
  type,
  onLaunchSession,
  hasClosestSession,
  noShadow,
}) => {
  const user = useSelector(state => state.account);
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const { user: loggedInUser } = useAccount();
  const shouldBeDisabled =
    isLaunchPlan ||
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_A;
  return (
    <Card
      classes={{
        root: noShadow || isSales ? 'card' : 'card bs-nobel',
      }}
      style={noShadow || isSales ? { boxShadow: 'none', minHeight: '155px' } : { minHeight: '155px' }}
    >
      <CardHeader
        action={
          onLaunchSession ? (
            <>
              <VideoButton
                disabled={
                  user?.user?.isPartnerCoach === false && shouldBeDisabled === false && !hasClosestSession
                    ? false
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false && !hasClosestSession
                    ? true
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true && !hasClosestSession
                    ? false
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false && !hasClosestSession
                    ? true
                    : false
                }
                handleClick={onLaunchSession}
              />
              <Link
                to={
                  user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                    ? `/edit-contribution/${id}`
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                    ? `/edit-contribution/${id}`
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : `/edit-contribution/${id}`
                }
              >
                <EditButton
                  disabled={
                    user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                      ? false
                      : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                      ? true
                      : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                      ? false
                      : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                      ? true
                      : false
                  }
                />
              </Link>
            </>
          ) : (
            moreLink && (
              <Link
                to={
                  user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                    ? moreLink
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                    ? moreLink
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : moreLink
                }
              >
                <IconButton aria-label="settings">
                  <MoreHorizIcon
                    color={
                      user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                        ? 'primary'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disabled'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                        ? 'primary'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                </IconButton>
              </Link>
            )
          )
        }
        classes={{
          root: classNames({ 'card__header-sales': isSales }),
          title: 'card__title-name',
          subheader: classNames({ 'card__title-session': isSession }),
          action: classNames('card__action', { 'card__action-sales': isSales }),
        }}
        title={
          !id ? (
            title
          ) : (
            <Link
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? 'card__title-link'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'card__title-link-disabled'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? 'card__title-link'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'card__title-link-disabled'
                  : 'card__title-link'
              }
              to={{
                pathname:
                  user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                    ? `/contribution-view/${id}/sessions`
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                    ? `/contribution-view/${id}/sessions`
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? null
                    : `/contribution-view/${id}/sessions`,
                state: { sessionId },
              }}
            >
              {title}
            </Link>
          )
        }
        subheader={
          <Link
            className={
              user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                ? 'card__title-link'
                : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                ? 'card__title-link-disabled'
                : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                ? 'card__title-link'
                : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                ? 'card__title-link-disabled'
                : 'card__title-link'
            }
            to={{
              pathname:
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? `/contribution-view/${id}/sessions`
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? `/contribution-view/${id}/sessions`
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? null
                  : `/contribution-view/${id}/sessions`,
              state: { sessionId },
            }}
          >
            {subTitle && `${subTitle}, ${hasClosestSession ? types[type] : ''}`}
          </Link>
        }
      />
      <CardContent
        classes={{
          root: classNames('card__content', { 'card__content-sales': isSales }),
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

CardContainer.defaultProps = {
  isSession: false,
  isSales: false,
  hasClosestSession: false,
  id: undefined,
  moreLink: undefined,
  title: '',
  subTitle: '',
  type: '',
  onLaunchSession: undefined,
};

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isSession: PropTypes.bool,
  isSales: PropTypes.bool,
  hasClosestSession: PropTypes.bool,
  id: PropTypes.string,
  moreLink: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  type: PropTypes.string,
  onLaunchSession: PropTypes.func,
};

export default CardContainer;
