import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import { EmailHeading } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import { Switch, StyledInput, StyledSlider } from '../../../components/switch/style';
import EditButton from '../../../components/UI/ListButtons/EditButton';
import ViewButton from '../../../components/UI/ListButtons/ViewButton';
import ChatIcon from './components/assets/Icons/Chat-icon.png';
import CommunitySvg from '../../../components/Icons/CommunityIcon';
import EnrolementSvg from '../../../components/Icons/EnrollmentIcon';
import SessionSvg from '../../../components/Icons/ReminderIcon';
import { useState } from 'react';
import {
  getCommunicationTabData,
  updateEmailTemplate,
  disableAllEmails,
  disableEmailDoNotRemindMe,
  updateIsNewleyCreated,
} from 'services/communicationTab.service ';
import useContribution from '../hooks/useContribution';
import { useEffect } from 'react';
import Loader from 'components/UI/Loader';
import { useStyles } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import styled from 'styled-components';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import './ContributionCommunicationPanel.scss';
import { TOOLTIP } from '../../../../src/constants';
import { colors } from 'utils/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useAccount } from 'hooks';
import { UserRoles } from 'helpers/constants';
import Modal from 'components/UI/Modal';
import CoachImage from 'assets/chatlogo.png';
import Joyride, { EVENTS } from 'react-joyride';
import Button from 'components/FormUI/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { lightOrDark } from 'utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FeatureFeedbackModal from 'components/Modals/FeatureFeedbackModal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNewlyCreated } from 'actions/contributions';

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledGrid = styled(Grid)`
  margin: 10px 0px;
`;

const StyledEditButton = styled(EditButton)`
  padding: 0px;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 2%;

  ${({ mobileView }) =>
    mobileView &&
    `
    height: 60%;
  width:10%;
  `}
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;

const EnableContainerHeading = styled.div`
  color: #215c73;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  text-align: start;
  margin-bottom: 10px;
`;

const EnableContainerText = styled.div`
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : '#000')};
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
  text-align: start;
`;

const EnableOutsideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const sentEmailText = 'If you disable this email, your clients will no longer receive this email';
const useBrandingText =
  'On Choosing custom your emails will be branded as per your customized contribution colors and logo.';

export default function ContributionCommunicationPanel({ click, getApiCall }) {
  const [customTemData, setcustomTemData] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [initialMode, setinitialMode] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [accordionOpen1, setAccordionOpen1] = useState(false);
  const [accordionOpen2, setAccordionOpen2] = useState(false);
  const [accordionOpen3, setAccordionOpen3] = useState(false);
  const [accordionOpen4, setAccordionOpen4] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const contribution = useContribution();
  const isNewlyCreated = useSelector(state => state.contributions?.isNewlyCreated);
  let colorToUse = determineColorToUse(contribution);
  const { id: contributionId } = contribution;
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const NotificationSettingTextColor = isDarkThemeEnabled ? colorToUse?.AccentColorCode : '#215C73';
  const NotificationSettingBgColor = isDarkThemeEnabled ? '#3D4246' : '#F1F8FF';
  const styledProps = {
    backgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };

  const classes = useStyles(styledProps);

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [loader, setLoader] = useState(false);
  const getCommunicationData = () => {
    getCommunicationTabData(contributionId)
      .then(res => {
        setLoader(false);
        setcustomTemData(res?.customTemplates);
      })
      .catch(err => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getCommunicationData();
  }, [contributionId, getApiCall, accordionOpen, accordionOpen1, accordionOpen2, accordionOpen3, accordionOpen4]);

  const [isFeedbackPopupOpen, setIsFeedbackOpen] = useState(false);

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
    dispatch(setIsNewlyCreated(false));
  };

  const handleFinishFeedbackCallback = () => {
    dispatch(setIsNewlyCreated(false));
    setEmailAutomations(true);
  };

  useEffect(() => {
    if (isNewlyCreated) {
      if (contribution?.showRatingFeedbackPopUp) {
        setIsFeedbackOpen(true);
      } else {
        setEmailAutomations(true);
      }
      updateIsNewleyCreated({ id: contributionId, type: 'Contribution' });
    }
  }, [contribution, contributionId]);

  const EnrollmentSales = customTemData.filter(el => el.category === 'Enrollment and Sales');

  const EmailNotification = customTemData.filter(el => el.category === 'My Email Notification');
  const SessionContent = customTemData.filter(el => el.category === 'Sessions and Content');
  const Community = customTemData.filter(el => el.category === 'Community');
  const Chat = customTemData.filter(el => el.category === 'Chat');
  const [emailTem, setemailTem] = useState([true, false]);
  const switchFun = (item, e) => {
    setLoader(true);
    const emailValue = e.target.checked.toString();
    item.sendEmailAllowed = emailValue;
    item['contributionId'] = contributionId;
    updateEmailTemplate(item)
      .then(res => {
        getCommunicationData();
      })
      .catch(err => {});
  };

  const switchFunCustomBranding = (item, e) => {
    setLoader(true);
    item.isCustomBrandingColorsEnabled = e.target.checked;
    item['contributionId'] = contributionId;
    updateEmailTemplate(item)
      .then(res => {
        getCommunicationData();
      })
      .catch(err => {});
  };

  function rightRotateByOne(arr) {
    if (!arr || arr.length === 0) {
      return arr;
    } else {
      const lastElement = arr.pop();
      arr.unshift(lastElement);
      return arr;
    }
  }

  const [stepIndex, setStepIndex] = useState(0);
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [emailAutomations, setEmailAutomations] = useState(false);
  const [automationsloader, setAutomationsLoader] = useState(false);
  const [run, setRun] = useState(false);
  const categories = ['Enrollment and Sales', 'My Email Notification', 'Sessions and Content', 'Community'];
  const emailValue = false;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const [selected, setSelected] = useState(false);

  const [steps, setSteps] = useState([]);

  let joyRideHelpers;
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const onEmailAutomationsSubmit = () => {
    dispatch(setIsNewlyCreated(false));
    setEmailAutomations(false);
    if (contribution.dontRemindMeCustomEmailPopup === false) {
      setRun(true);
    }
  };

  const doNotRemindMe = checked => {
    setSelected(checked);
    disableEmailDoNotRemindMe(contributionId);
    setTimeout(() => {
      setRun(false);
    }, 500);
  };

  useEffect(() => {
    setSteps([
      {
        disableBeacon: true,
        hideFooter: true,
        content: (
          <div
            className="step1"
            style={{ paddingLeft: '15px', paddingRight: '15px', backgroundColor: isDarkThemeEnabled ? '#2d2f31' : '' }}
          >
            <div
              className="image"
              style={{
                height: '70px',
                width: '70px',
                backgroundColor: 'white',
                position: 'absolute',
                borderRadius: '100px',
                top: -45,
                borderColor: '#215C73',
                borderWidth: '5px',
              }}
            >
              <StyledImg previewImg={CoachImage} />
            </div>
            <EnableContainerHeading>Enable & Customize Client Notifications</EnableContainerHeading>
            <EnableContainerText isDarkThemeEnabled={isDarkThemeEnabled}>
              Cohere's automated client notifications streamline communication and enhance engagement. These emails
              cover everything from enrollment and session reminders to community posts and more. Toggle the switch to
              enable them and tailor content to match your coaching style and your clients' preferences.
            </EnableContainerText>
            <EnableOutsideContainer>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                  style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                  control={
                    <Checkbox
                      checked={selected}
                      onChange={({ target: { checked } }) => doNotRemindMe(checked)}
                      color="primary"
                    />
                  }
                  label="Don’t remind me again"
                />
              </div>
              <Button
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                style={{ marginTop: '10px', height: '29px', padding: '7px 16px' }}
                onClick={() => {
                  setRun(false);
                }}
              >
                Got It
              </Button>
            </EnableOutsideContainer>
          </div>
        ),
        placement: 'top',
        target: '#panel1bh-header-container',
      },
    ]);
  }, [selected]);

  const onEmailAutomationsCancel = () => {
    setAutomationsLoader(true);
    disableAllEmails(contributionId).then(res => {
      setAutomationsLoader(false);
      dispatch(setIsNewlyCreated(false));
      setEmailAutomations(false);
      getCommunicationData();
    });
  };

  return (
    <>
      {!mobileView && (
        <>
          <Grid container className={isDarkThemeEnabled ? classes.PannelTopBarTopDark : classes.PannelTopBarTop}>
            Email Notifications
          </Grid>
          <Grid container className={classes.PannelTopBar}>
            <Grid md={3}>
              <EmailHeading className={classes.EmailText}>Email Templates</EmailHeading>
            </Grid>
            <Grid md={1}>
              <EmailHeading className={classes.ActionsText}>Edit</EmailHeading>
            </Grid>
            <Grid md={4}>
              <EmailHeading>Email Subject</EmailHeading>
            </Grid>
            {/* <Grid md={3}>
              <EmailHeading style={{ textAlign: 'center' }}>Customize</EmailHeading>
            </Grid> */}
            <Grid md={2}>
              <EmailHeading>
                Send this email
                <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                  <StyledInfoIcon htmlColor={colorToUse?.PrimaryColorCode} style={{ marginRight: '10px' }} />
                </Tooltip>
              </EmailHeading>
            </Grid>
            <Grid md={2}>
              <EmailHeading>
                Use my branding
                <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                  <StyledInfoIcon htmlColor={colorToUse?.PrimaryColorCode} style={{ marginRight: '10px' }} />
                </Tooltip>
              </EmailHeading>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <div>
                <Accordion expanded={accordionOpen} id="panel1bh-header-container">
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    {/* <img src={EnrolementIcon} right="5" /> */}
                    <Typography
                      sx={{
                        marginTop: '5px',
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                      className={classes.TabsTyphography}
                    >
                      Enrollment and Sales
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 16px 16px',
                      backgroundColor: themedCardBackgroundColor,
                      color: themedColor,
                    }}
                  >
                    {rightRotateByOne(EnrollmentSales)?.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          {/* <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}> {isEmailEnabled ? 'Custom' : 'Default'}</p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isEmailEnabled}
                                  value={isEmailEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={isEmailEnabled}
                                />
                                <StyledSlider className="slider round" />
                              </Switch>
                            </div>
                          </Grid> */}
                          <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen1}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen1(!accordionOpen1)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <SessionSvg color={NotificationSettingTextColor} height="60%" width="2%" />

                    <Typography
                      sx={{
                        marginTop: '5px',
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                    >
                      Session Reminders and Self-Paced Content
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {SessionContent.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == true
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen2}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen2(!accordionOpen2)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <CommunitySvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    <Typography
                      sx={{
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                      }}
                    >
                      Community, Posts and Comments
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {Community.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonEnb}>
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen4}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen4(!accordionOpen4)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="2%" />
                    {/* <img src={EnrolementIcon} right="5" /> */}
                    <Typography
                      sx={{
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        marginTop: '5px',
                      }}
                      className={classes.TabsTyphography}
                    >
                      My Email Notifications
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 16px 16px',
                      color: themedColor,
                      backgroundColor: themedCardBackgroundColor,
                    }}
                  >
                    {EmailNotification.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <StyledGrid container>
                          <Grid md={3}>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid md={1}>
                            <div className={classes.ButtonViewHide}>
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />
                            </div>
                          </Grid>
                          <Grid md={4}>
                            <p>{emailSubject}</p>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid md={2}>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Enable' : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                        </StyledGrid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {mobileView && (
        <>
          <Grid container>
            <Grid item md={12} className={classes.MobileViewMain}>
              <div>
                <Accordion expanded={accordionOpen} id="panel1bh-header-container">
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Enrollment and Sales
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {EnrollmentSales.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        name,
                        sendEmailAllowed,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewMainSalesCon}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send this Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use my Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading mobileView={mobileView}>Actions</EmailHeading>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen1}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen1(!accordionOpen1)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <SessionSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Session Reminders and Self-Paced Content
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}></Typography> */}
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '30px 16px 16px' }}>
                    {SessionContent.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.SessionContentMain}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send this Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : sendEmailAllowed === true
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? 'Enable'
                                              : sendEmailAllowed === 'true'
                                              ? 'Enable'
                                              : sendEmailAllowed === true
                                              ? 'Enable'
                                              : 'Disable',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use my Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Actions</EmailHeading>

                            <div className={classes.SessionContentBtn}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen2}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen2(!accordionOpen2)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <CommunitySvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '14px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      Community, Posts and Comments
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '30px 16px 16px' }}>
                    {Community.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewCommunity}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send this Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use my Branding</div>{' '}
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{}}>Actions</EmailHeading>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={accordionOpen4}>
                  <AccordionSummary
                    style={{
                      backgroundColor: NotificationSettingBgColor,
                      padding: '0 10px',
                      width: '100%',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen4(!accordionOpen4)}
                    className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <EnrolementSvg color={NotificationSettingTextColor} height="60%" width="10%" />
                    <Typography
                      sx={{
                        flexShrink: 0,
                        paddingLeft: '10px',
                        fontSize: '16px',
                        fontWeight: '850',
                        fontStyle: 'normal',
                        color: NotificationSettingTextColor,
                        fontFamily: 'Avenir',
                        width: '90%',
                      }}
                    >
                      My Email Notifications
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}></Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordianDetails}>
                    {EmailNotification.map((item, index) => {
                      const {
                        category,
                        emailSubject,
                        emailText,
                        emailType,
                        isEmailEnabled,
                        sendEmailAllowed,
                        name,
                        isCustomBrandingColorsEnabled,
                        currentColors,
                      } = item;
                      return (
                        <Grid container className={classes.MobileViewMainSalesCon}>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Template</EmailHeading>
                            <p
                              onClick={() => {
                                click(item);
                              }}
                              style={{ maxWidth: '120px', cursor: 'pointer' }}
                            >
                              {name}
                            </p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading>Email Subject</EmailHeading>
                            <p style={{ maxWidth: '140px' }}>{emailSubject}</p>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Send this Email</div>
                              <Tooltip title={sentEmailText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {sendEmailAllowed === null
                                  ? 'Enable'
                                  : sendEmailAllowed === 'true'
                                  ? 'Enable'
                                  : 'Disable'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={
                                    sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false
                                  }
                                  value={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFun(item, e);
                                  }}
                                  checked={sendEmailAllowed === null ? true : sendEmailAllowed == 'true' ? true : false}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor:
                                            sendEmailAllowed === null
                                              ? colorToUse?.PrimaryColorCode
                                              : sendEmailAllowed == 'true'
                                              ? colorToUse?.PrimaryColorCode
                                              : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', width: 70 }}>Use my Branding</div>
                              <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                                <StyledInfoIcon
                                  htmlColor={contribution.brandingColors.PrimaryColorCode}
                                  style={{ marginRight: '10px' }}
                                />
                              </Tooltip>
                            </EmailHeading>
                            <div style={{ display: 'flex' }}>
                              <p className={classes.ButtonViewHide}>
                                {' '}
                                {isCustomBrandingColorsEnabled ? 'Custom' : 'Default'}
                              </p>
                              <Switch className="switch" style={{ marginLeft: '6px' }}>
                                <StyledInput
                                  defaultChecked={isCustomBrandingColorsEnabled}
                                  value={isCustomBrandingColorsEnabled}
                                  type="checkbox"
                                  onChange={e => {
                                    switchFunCustomBranding(item, e);
                                  }}
                                  checked={isCustomBrandingColorsEnabled}
                                />
                                <StyledSlider
                                  style={
                                    isDarkThemeEnabled
                                      ? {
                                          backgroundColor: isCustomBrandingColorsEnabled
                                            ? colorToUse?.PrimaryColorCode
                                            : '#ccc',
                                        }
                                      : {}
                                  }
                                  className="slider round"
                                />
                              </Switch>
                            </div>
                          </Grid>
                          <Grid sm={6} md={6} style={{ width: '50%' }}>
                            <EmailHeading mobileView={mobileView}>Actions</EmailHeading>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ViewButton PLeft />
                              <StyledEditButton
                                handleOnClick={() => {
                                  click(item);
                                }}
                                color={isDarkThemeEnabled ? colorToUse?.PrimaryColorCode : ''}
                              />{' '}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            {loader && <Loader />}
          </Grid>
        </>
      )}

      {automationsloader && <Loader />}
      <Modal
        isOpen={isCoach && emailAutomations}
        title="Customize Email Automations?"
        submitTitle="Leave Enabled & Customize Now"
        cancelTitle="Disable Emails & Customize Later"
        onSubmit={onEmailAutomationsSubmit}
        onCancel={onEmailAutomationsCancel}
        closeClick={true}
        disableOverlayClick
        onCloseClick={() => {
          setEmailAutomations(false);
          dispatch(setIsNewlyCreated(false));
        }}
        className="delete-application-form-modal"
      >
        <p style={{ color: isDarkThemeEnabled ? 'white' : '' }}>
          Would you like to personalize the automated emails your clients will receive for {contribution?.title}? By
          default, these emails are already enabled. What would you like to do next?
        </p>
      </Modal>
      <FeatureFeedbackModal
        isOpen={isFeedbackPopupOpen}
        handleClose={handleCloseFeedback}
        isDarkModeEnabled={contribution?.isDarkModeEnabled}
        feature="Contribution"
        mobileView={mobileView}
        handleFinishFeedback={handleFinishFeedbackCallback}
      />
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
            backgroundColor: isDarkThemeEnabled ? '#2d2f31' : 'rgba(255, 255, 255)',
          },
        }}
      />
    </>
  );
}
