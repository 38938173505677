import React, { useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

import * as calendarsActions from 'actions/calendars';
import { AddCalendarModalContent } from './AddCalendarModalContent';
import { SendInvitesFromModalContent } from './SendInvitesFromModalContent';
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants';
import { SEND_INVITE_MODAL_FIELDS } from './AddCalendarModalConstants';

const SendInvitesFromModal = ({
  isOpen,
  onModalClose,
  onSubmit,
  accountsForConflictsCheck,
  accountForSendReminders,
  putMailsForConfictCheck,
  putMailForCalendarReminders,
  lastAddedMail,
  oneToOneStartDate,
  oneToOneEndDate,
  fetchCalendars,
  getAcсountsForConflictsCheck,
  calendars,
  values,
  setFieldValue,
  ...restProps
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSubmit = (e, formProps) => {
    // e.preventDefault();

    if (formProps.values.externalCalendarEmail) {
      setFieldValue('externalCalendarEmail', formProps.values.externalCalendarEmail);
    }
    if (onSubmit) onSubmit(formProps.values.externalCalendarEmail);
  };

  useEffect(() => {
    // fetchCalendars();
    getAcсountsForConflictsCheck();
  }, []);

  const preparedCheckedCalendars =
    accountsForConflictsCheck?.length > 0 ? accountsForConflictsCheck.map(account => account.emailAddress) : [];
  return (
    <Formik
      initialValues={{
        [SEND_INVITE_MODAL_FIELDS.externalCalendarEmail]: values.externalCalendarEmail || null,
      }}

      // enableReinitialize
    >
      {formProps => (
        <Modal
          title="Send Invites From"
          isOpen={isOpen}
          onCancel={onModalClose}
          onSubmit={e => handleSubmit(e, formProps)}
          submitTitle={mobileView ? 'Set' : 'Set calendar'}
          brandingColor={false}
          hiddenCancel
          minWidth={'600px'}
        >
          <SendInvitesFromModalContent
            {...restProps}
            setFieldValue={formProps.setFieldValue}
            // lastAddedMail={lastAddedMail}
            values={formProps.values}
            calendars={calendars}
            // preparedCheckedCalendars={preparedCheckedCalendars}
          />
        </Modal>
      )}
    </Formik>
  );
};

SendInvitesFromModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  oneToOneStartDate: PropTypes.shape(),
  oneToOneEndDate: PropTypes.shape(),
};

const mapStateToProps = ({ calendars, oneToOneData }) => ({
  calendars: calendars.calendarsAccounts,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  accountForSendReminders: calendars.accountForSendReminders,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
});

const actions = {
  putMailsForConfictCheck: calendarsActions.putMailsForConfictCheck,
  putMailForCalendarReminders: calendarsActions.putMailForCalendarReminders,
  fetchCalendars: calendarsActions.fetchCalendars,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
};

export default connect(mapStateToProps, actions)(SendInvitesFromModal);
