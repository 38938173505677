import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  loaderWrapper: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    height: '100%',
    position: 'absolute',
  },

  inputField: {
    backgroundColor: ({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white'),
    borderRadius: '10px',
    width: '100%',
    border: '1px solid #f5f0f0',
    marginTop: '5px',
  },

  inputFieldForCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    height: '55px',
    padding: '20px 15px',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },

  inputFieldForCardDark: {
    backgroundColor: 'rgb(37, 39, 40)',
    color: '#ffffff !important',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    height: '55px',
    padding: '20px 15px',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },
  nameInputFieldForCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },

  select: {
    width: '100%',
    color: ({ color }) => color,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    height: '53px',

    '& option': {
      color: ({ color }) => color,
      backgroundColor: ({ backgroundColor }) => `${backgroundColor} !important`,
    },
    '&:before': {
      color: ({ color }) => color,
    },
    '&:after': {
      color: ({ color }) => color,
    },
  },
  discountCode: {},
  discountCodeTextFied: {},

  redeemButton: {
    color: theme.palette.colors.lightBrown,
    fontFamily: 'Inter',
    fontWeight: 600,
    textTransform: 'none',
  },

  redeemButtonTemp1: {
    color: '#6999CA',
    fontFamily: 'Inter',
    fontWeight: 600,
    textTransform: 'none',
    // position: 'relative',
    // right: '7.25rem',
  },
  themedTextColor: {
    color: ({ color }) => color,
  },
  themedTextField: {
    '& input': {
      color: ({ color }) => color,
      '&::placeholder': {
        color: ({ color }) => color,
      },
    },
  },

  redeemButtonTemp2: {
    color: '#fff',
    fontFamily: 'Inter',
    fontWeight: 600,
    textTransform: 'none',
    background: '#D08ACD',
    padding: '15px 50px',

    // '&:hover': {
    //   backgroundColor: ColorSchemeService().PrimaryColorCode,
    // },
    // position: 'relative',
    // right: '7.25rem',
  },

  sectionWrapper: {
    // background: '#f7f9f9',
    height: '100%',
    // borderRadius: '10px',
  },

  customSectionWrapper: {
    backgroundColor: ({ cardBackgroundColor }) => `${cardBackgroundColor} !important`,
    height: '100%',
    borderRadius: '10px',
    paddingBottom: '10px',
  },

  wrapper: {
    position: 'relative',
  },

  noBorder: {
    border: 'none',
  },

  priceSection: {
    padding: '5px 50px',
  },
});

export const useStyles = makeStyles(styles);
