import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/FormUI/Button';
import { useAccount, useHttp, useVideoChatActions } from 'hooks';
import { LiveVideoProvider } from 'constants.js';
import { openInNewWindow } from 'services/links';
import { connect } from 'react-redux';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType, UserRoles } from 'helpers/constants';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import { DarkEnabledPrimaryFilledButton } from 'pages/ContributionView/ContributionSessions/component/styled';

const JoinSessionTime = ({
  brandingColors,
  contributions,
  user,
  session,
  contribution: { id, type, chat, liveVideoServiceProvider, coachPhoneNumber, serviceProviderName, isDarkModeEnabled },
  sessionTime,
  isLaunched,
  mobileView,
  isSessionView = false,
}) => {
  const { request, loading } = useHttp();
  const { startVideoChat } = useVideoChatActions();
  const { currentRole } = useAccount();
  const [launchModal, setLaunchModal] = useState(false);
  const isCoach = currentRole === UserRoles.cohealer;

  const activeTemplate = contributions?.activeContribution?.activeTemplate || TemplateType.TemplateThree;

  // const isCustomBrandingColorsActive = contributions?.activeContribution?.isCustomBrandingColorsActive;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? brandingColors?.PrimaryColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';
  const shouldChangeStyle =
    !isCoach && activeTemplate != TemplateType.TemplateThree && type === ContributionType.contributionOneToOne;
  let colorToUse = determineColorToUse(contributions?.activeContribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  // const btnColor = ColorSchemeService().PrimaryColorCode;

  const isCustomLiveVideoServiceProvider = useMemo(() => {
    return liveVideoServiceProvider && liveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value;
  }, [liveVideoServiceProvider]);

  const isZoomVideoServiceProvider = useMemo(() => {
    return liveVideoServiceProvider && liveVideoServiceProvider.providerName == LiveVideoProvider.Zoom.value;
  }, [liveVideoServiceProvider]);

  const onJoinSession = event => {
    event.stopPropagation();
    if (isZoomVideoServiceProvider) {
      window.open(sessionTime.zoomMeetingData.joinUrl, '_blank');
    } else if (isCustomLiveVideoServiceProvider) {
      openInNewWindow(liveVideoServiceProvider.customLink);
    } else if (
      sessionTime?.isPhoneNumberEnabledSession &&
      (sessionTime?.isPhoneNumberAdded || sessionTime?.isCoachNumberAdded)
    ) {
      setLaunchModal(true);
    } else {
      request('/Video/GetClientToken', 'POST', {
        contributionId: id,
        classId: sessionTime.id,
        identityName: `${user.firstName} ${user.lastName}`,
      }).then(({ token }) =>
        startVideoChat({
          contributionId: id,
          type,
          sessionId: session?.id,
          classId: sessionTime.id,
          title: session?.title,
          chatId: chat?.sid,
          token,
        }),
      );
    }
  };

  const disabledBtn =
    loading ||
    (!isLaunched &&
      !(
        isCustomLiveVideoServiceProvider ||
        (sessionTime?.isPhoneNumberEnabledSession &&
          (isCoach
            ? sessionTime?.isPhoneNumberAdded && !sessionTime?.isCoachNumberAdded
            : sessionTime?.isPhoneNumberAdded && sessionTime?.isCoachNumberAdded))
      ));

  const tempOneTwoStyling = {
    // padding: mobileView ? '10px 15px' : '7px 15px',
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: '900',
    backgroundColor: disabledBtn ? 'lightgray' : '',
    color: disabledBtn ? 'white' : '',
    fill: disabledBtn ? '#9b9b9b' : '',
    cursor: disabledBtn ? 'not-allowed' : '',
    border: disabledBtn ? 'none' : '',
    // marginTop: mobileView ? '12px' : '',
    fontFamily: 'Avenir',
    width: mobileView ? '100%' : '',
    padding: mobileView ? '16px 40px' : '',
  };

  return (
    <>
      {isSessionView ? (
        <DarkEnabledPrimaryFilledButton
          autoWidth
          backgroundColor={colorToUse.PrimaryColorCode}
          textColor={textColor}
          onClick={onJoinSession}
          disabled={disabledBtn}
          mobileView={mobileView}
          {...{ isDarkModeEnabled }}
        >
          {sessionTime?.isPhoneNumberEnabledSession && sessionTime?.isCoachNumberAdded
            ? `Call ${coachPhoneNumber}`
            : 'Join Session'}
        </DarkEnabledPrimaryFilledButton>
      ) : mobileView ? (
        <Button
          textColor={textColor}
          backgroundColor={btnColor}
          style={
            shouldChangeStyle
              ? { ...tempOneTwoStyling, height: '48px', padding: '' }
              : {
                  backgroundColor: `${btnColor} !important`,
                  fontSize: '14px',
                  fontWeight: '900',
                  fontFamily: 'Avenir',
                  height: '48px',
                  padding: '',
                }
          }
          autoWidth
          onClick={onJoinSession}
          disabled={disabledBtn}
          mobileView={mobileView}
        >
          {sessionTime?.isPhoneNumberEnabledSession && sessionTime?.isCoachNumberAdded
            ? `Call ${coachPhoneNumber}`
            : 'Join Session'}
        </Button>
      ) : (
        <Button
          textColor={textColor}
          backgroundColor={btnColor}
          style={
            shouldChangeStyle
              ? { ...tempOneTwoStyling }
              : {
                  backgroundColor: `${btnColor} !important`,
                  fontSize: '14px',
                  fontWeight: '900',
                  fontFamily: 'Avenir',
                }
          }
          autoWidth
          onClick={onJoinSession}
          disabled={disabledBtn}
          mobileView={mobileView}
        >
          {sessionTime?.isPhoneNumberEnabledSession && sessionTime?.isCoachNumberAdded
            ? `Call ${coachPhoneNumber}`
            : 'Join Session'}
        </Button>
      )}

      {launchModal && (
        <Modal
          title={`Call ${serviceProviderName}`}
          isOpen={launchModal}
          onCancel={() => setLaunchModal(false)}
          submitTitle="Ok"
          onSubmit={() => setLaunchModal(false)}
          hiddenCancel
          applyTheming
        >
          Call {serviceProviderName}, {coachPhoneNumber}
        </Modal>
      )}
    </>
  );
};

JoinSessionTime.defaultProps = {
  session: {},
};

JoinSessionTime.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
    isPhoneNumberEnabledSession: PropTypes.bool.isRequired,
    isCoachNumberAdded: PropTypes.bool,
    isPhoneNumberAdded: PropTypes.bool,
  }).isRequired,
  isLaunched: PropTypes.bool.isRequired,
  mobileView: PropTypes.bool,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.activeContribution?.brandingColors,
  contributions: contributions,
});

export default connect(mapStateToProps)(JoinSessionTime);
