import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import { LabelText } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles/styles';
import 'styles/creditCard.scss';
import useContribution from '../../hooks/useContribution.js';
import { useStyles } from '../PurchaseBlock/hooks/useStyles.jsx';
import { determineColorToUse } from 'services/contributions.service.js';
export const StyledInput = styled.input`
  padding: 6px 11px 10px 11px;
  // border: 0;
  // border-bottom: 1px solid #b3b3b3;
  outline: none;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f5f0f0 !important;
  border-radius: 20px !important;
  background-color: white !important;
  &:hover {
    border-color: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    border: 1px solid #f5f0f0 !important;
    border-radius: 20px !important;
    background-color: white !important;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #ff4d4f;
    `}
`;

const StyledCustomToSGrid = styled(Grid)`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  text-decoration: underline;
`;

const StyledLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
`;

const CARD_OPTIONS = {
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      fontFamily: `Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#2b2b2b' },
      '::placeholder': { color: 'rgba(0, 0, 0, 0.2)' },
    },
    invalid: {
      color: '#ff4d4f',
    },
  },
};

const CARD_NUMBER_OPTIONS = {
  ...CARD_OPTIONS,
  placeholder: 'Card Number',
};

function CreditCard({ handleChange, touched, errors, setShowTerms }) {
  const { serviceProviderName, customToS } = useContribution();
  const classNames = useStyles();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  return (
    <>
      <LabelText>Payment Method</LabelText>
      <br />
      <br />
      <Grid spacing={2}>
        <Grid item sm={12} md={12} xs={12} style={{ margin: '8px 0px' }}>
          <Field
            // className={classNames.inputField}
            // className="card_name"
            component={StyledInput}
            name="Name"
            placeholder="Name on Card"
            type="text"
            onChange={handleChange('Name')}
            error={touched.Name && errors.Name}
          />
        </Grid>
        <Grid item sm={12} xs={12} style={{ margin: '8px 0px' }}>
          <CardNumberElement className={classNames.inputField} options={CARD_NUMBER_OPTIONS} />
        </Grid>
        <Grid item sm={12} xs={12} style={{ margin: '8px 0px' }}>
          <CardExpiryElement className={classNames.inputField} options={CARD_OPTIONS} />
        </Grid>
        <Grid item sm={12} xs={12} style={{ margin: '8px 0px' }}>
          <CardCvcElement className={classNames.inputField} options={CARD_OPTIONS} />
        </Grid>

        <Grid item>
          <span className="mr-1">By clicking Reserve, I agree to the</span>
          <StyledLink
            href="/"
            color={colorToUse?.PrimaryColorCode}
            onClick={e => {
              e.preventDefault();
              setShowTerms(true);
            }}
          >
            Terms and Conditions.
          </StyledLink>
        </Grid>
        {customToS && (
          <Grid item>
            <span className="mr-1">By clicking Reserve, I also agree to {serviceProviderName}'s</span>
            <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
              Terms and Conditions.
            </StyledLink>
          </Grid>
        )}
      </Grid>
    </>
  );
}

CreditCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  setShowTerms: PropTypes.func.isRequired,
};

export default CreditCard;
