import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import ProgressBar from '@ramonak/react-progress-bar';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { useAccount, useEndVideoChat } from 'hooks';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { getItem } from 'services/localStorage.service';
import {
  fetchCohealerContribution,
  rescheduleSession,
  fetchContributionTimes,
  setRescheduleModal,
  setRescheduleFormerSessionDetail,
  setRescheduleParticipantInfo,
} from 'actions/contributions';
import Button from 'components/FormUI/Button';
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import styled from 'styled-components';
import { TIMEZONES } from 'constants.js';
import { lightOrDark } from 'utils/utils';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import SessionsContainer from '../SessionsContainer/SessionsContainer';
import CalendarContributionView from '../../../components/CalendarContributionView/CalendarContributionView';
import RefuseModal from '../../../components/RefuseModal';
import LaunchSessionTimeModal from './LaunchSessionTimeModal';
import SlotsModal from '../../../components/SlotsModal.js';
import TabSelector from '../../../../../components/UI/TabSelector.js';
import { FILTER_TABS_OPTIONS, FILTER_TABS_OPTIONS_COACH_PENDING, SESSIONS_TYPES } from '../../constants.js';
import { colors } from 'utils/styles';
import { UserRoles } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';
import ScheduleMeetingModal from 'pages/ContributionView/components/CalendarContributionView/ScheduleMeetingModal';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
import EasyBooking from '../EasyBooking/EasyBooking';
import SessionBookingThankyou from '../EasyBooking/components/SessionBookingThankyou';
import { setEasyBookingData } from 'actions/easyBooking';
import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';

const StyledTextField = styled(TextField)`
  width: 100%;

  .MuiInput-input {
    color: ${({ textColor }) => textColor};
  }
`;

const scrollToCalendar = ref => window.scrollTo(0, ref.current.offsetTop);

const CohealerOneToOneSessions = ({
  user,
  contribution,
  contribution: {
    id,
    title,
    availabilityTimes,
    activeTemplate,
    durations,
    serviceProviderName,
    participants,
    sessionIncrements,
  },
  contributionTimes,
  getCohealerContribution,
  rescheduleChosenSession,
  getContributionTimes,
}) => {
  const { currentRole } = useAccount();
  const [modalOpened, setModalOpened] = useState(false);
  const isCoach = currentRole === UserRoles.cohealer;
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [sessionIdForNotes, setSessionIdForNotes] = useState(null);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(contribution);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const [selectedSessionTime, setSelectedSessionTime] = useState(null);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [isCalendarRescheduleModalOpen, setCalendarRescheduleModal] = useState(false);
  const [calendarRescheduleModalStatus, setCalendarRescheduleModalStatus] = useState(null);
  const [currentRescheduleSession, setCurrentRescheduleSession] = useState(null);
  const [messageText, setMessageText] = useState('');
  const reschedulePopUp = useSelector(state => state.contributions.reschedulePopUp);
  const [rescheduleTo, setRescheduleTo] = useState(null);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const currentDate = moment();
  const dateToShow = currentRescheduleSession?.startTime;
  let colorToUse = determineColorToUse(contribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState('');

  useEffect(() => {
    if (currentRescheduleSession) {
      const { firstName, lastName } =
        (participants || []).find(client => client.id === currentRescheduleSession.participantId) || {};
      setClientFirstName(firstName);
      setClientLastName(lastName);
    }
  }, [currentRescheduleSession]);

  const executeScroll = () => {
    dispatch(setRescheduleModal(true));
    scrollToCalendar(calendarRef);
  };
  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };
  const handleCalendarRescheduleSubmit = () => {
    const rescheduleData = {
      rescheduleFromId: currentRescheduleSession.id,
      rescheduleToId: rescheduleTo.id,
      contributionId: id,
      note: messageText,
      offset: rescheduleTo.offset,
    };
    rescheduleChosenSession(rescheduleData).then(() => {
      setCalendarRescheduleModal(false);
      setMessageText(null);
      setCalendarRescheduleModalStatus(rescheduleTo);
    });
  };

  const handleCalendarRescheduleCancel = () => {
    setCalendarRescheduleModal(false);
    setRescheduleTo(null);
  };

  const handleCalendarRescheduleStatusModalSubmit = () => {
    setCalendarRescheduleModalStatus(null);
    setCurrentRescheduleSession(null);
    dispatch(setRescheduleModal(false));
  };

  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(id);
    getContributionTimes(id, true, sessionIncrements?.[0] || 0, durations?.[0]);
  }, [id, getCohealerContribution, getContributionTimes]);
  const { Popup, onShowPopup, handleSessionComplete } = useEndVideoChat(handleRefreshList);

  const duration = durations && durations.length ? durations[0] : 60; // temporary becauce there is null
  const events = getTimePeriodsForAvailability({
    availabilityPeriods: contributionTimes,
    duration,
    title,
    serviceProviderName,
  });

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [isSlotModalOpen, setSlotModalShowed] = useState(false);
  const [selectedFilterTab, setSelectedFilterTab] = useState(FILTER_TABS_OPTIONS[0].value);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [showSelfbookModal, setShowSelfbookModal] = useState(false);
  const [isResendFlow, setIsResendFlow] = useState(false);
  const [resendSession, setResendSession] = useState(null);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const closeModalHandler = useCallback(() => {
    setSlotModalShowed(false);
    dispatch(setRescheduleModal(false));
  }, []);
  const onCalendarDrillDownHandler = useCallback(
    day => {
      const startTimes = contributionTimes?.map(item => item.startTime);
      // Extract date from start times using Moment.js
      const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
      setSelectedDate(moment(day));
      if (reschedulePopUp === true) {
        setSlotModalShowed(true);
      } else {
        if (dates.includes(moment(day).format('YYYY-MM-DD'))) {
          setIsResendFlow(false);
          setShowSelfbookModal(true);
        }
      }
    },
    [reschedulePopUp, contributionTimes],
  );

  useEffect(() => {
    if (participants.length > 0) {
      const particips = [];
      for (let p of participants) {
        const name = {
          title: `${p.firstName} ${p.lastName}`,
          value: p.id,
        };
        particips.push(name);
      }

      // for (let p of contribution?.contributionPartners) {
      //   const name = {
      //     title: `${p.firstName} ${p.lastName}`,
      //     value: p.userId,
      //   };
      //   particips.push(name);
      // }

      // const currentUser = {
      //   title: `${user?.firstName}${user?.lastName}`,
      //   value: user?.id,
      // };
      // const coachUser = {
      //   title: `${contribution?.serviceProviderName?.trim()}`,
      //   value: contribution?.userId,
      // };
      // if (!isCoach) {
      //   particips.push(coachUser);
      // } else {
      //   particips.push(currentUser);
      // }
      const sortedParticips = particips?.sort((a, b) => a.title.localeCompare(b.title));
      setClients(sortedParticips);
      if (contributionTimes) {
        const startTimes = contributionTimes?.map(item => item.startTime);
        const filteredDates = startTimes?.filter(date => {
          const dateObj = moment(date);
          return dateObj.isSameOrAfter(currentDate, 'day');
        });
        setSelectedDate(filteredDates[0]);
      }
    }
  }, [participants, contributionTimes]);

  const onCalendarEventSelectHandler = useCallback(
    event => {
      setShowSelfbookModal(false);
      setSelectedDate(moment(moment(event.start)));
      if (reschedulePopUp === true) {
        setSlotModalShowed(true);
      } else {
        setIsResendFlow(false);
        setShowSelfbookModal(true);
      }
    },
    [showSelfbookModal, reschedulePopUp],
  );
  const onSelectEvent = event => {
    const { start, isBooked, bookedTimeId, offset } = event;
    const availableToReschedule = moment(start).isAfter(moment());
    if (rescheduleMode && availableToReschedule && !isBooked) {
      setRescheduleMode(false);
      setCalendarRescheduleModal(true);
      setRescheduleTo(event);
      setSlotModalShowed(false);
      return;
    }
    if (!event.isBooked) {
      setIsRefuseModalOpen(true);
      return;
    }

    setSelectedSessionTime({
      id: bookedTimeId,
      title: event.title,
      start: event.start,
      end: event.end,
      participantId: event.participantId,
      offset,
    });
  };

  const computeSessions = () => {
    let resultant = [];
    for (let time of availabilityTimes) {
      for (let booked of time.bookedTimes) {
        if (booked.isPurchaseConfirmed === false) return true;
      }
    }
  };
  return (
    <>
      {/* <div>
        <ProgressBar completed={50} bgColor="#116582" />
      </div> */}
      <TabSelector
        options={computeSessions() ? FILTER_TABS_OPTIONS_COACH_PENDING : FILTER_TABS_OPTIONS}
        selected={selectedFilterTab}
        onChange={setSelectedFilterTab}
        tabColor={colors.darkOceanBlue2}
        colorToUse={colorToUse}
        textColor={textColor}
        isCoach={isCoach}
        activeTemplate={activeTemplate || TemplateType.TemplateThree}
        isDarkThemeEnabled={isDarkThemeEnabled}
        clients={clients}
        filteredClients={filteredClients}
        setFilteredClients={setFilteredClients}
      />
      <SessionsContainer
        user={user}
        isCoach={isCoach}
        executeCalendarScroll={executeScroll}
        setRescheduleMode={setRescheduleMode}
        setCurrentRescheduleSession={setCurrentRescheduleSession}
        contribution={contribution}
        rescheduleMode={rescheduleMode}
        completionFilter={selectedFilterTab}
        filteredClients={filteredClients}
        renderActions={session => {
          const isHasRecordings = session.recordingInfos.length > 0;
          const participant = (participants || []).find(client => client.id === session.participantId) || {};
          const clientDisable = !moment(session.startTime).isAfter(moment().add(24, 'h')) || session.isCompleted;
          const isRescheduleDisabled = false;
          return !mobileView ? (
            <>
              {session?.id && !session.isCompleted && session.isPurchaseConfirmed && (
                <Button
                  autoWidth
                  textColor={colorToUse.AccentColorCode}
                  borderColor={isRescheduleDisabled ? '#9b9b9b' : colorToUse.AccentColorCode}
                  onClick={() => {
                    setRescheduleModalState(true);
                    setRescheduleSession(true);
                    dispatch(
                      setEasyBookingData.setData({
                        selectedSlot: session,
                        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
                          'h:mmA',
                        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format(
                          'MMMM DD, yyyy',
                        )}`,
                        helpingMaterial: getItem('helpingMaterial'),
                        isBookedTime: true,
                      }),
                    );
                    dispatch(
                      setRescheduleFormerSessionDetail({
                        selectedSlot: session,
                        timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
                          'h:mmA',
                        )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format(
                          'MMMM DD, yyyy',
                        )}`,
                        isBookedTime: true,
                        helpingMaterial: getItem('helpingMaterial'),
                      }),
                    );
                    dispatch(setRescheduleParticipantInfo(participant));
                  }}
                  disabled={isRescheduleDisabled}
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  Reschedule
                </Button>
              )}
              {!session.isCompleted ? (
                session.isPurchaseConfirmed ? (
                  <Button
                    style={{ marginRight: '10px' }}
                    invert
                    textColor={colorToUse.AccentColorCode}
                    borderColor={colorToUse.AccentColorCode}
                    autoWidth
                    onClick={() => onShowPopup(contribution.id, session.id)}
                  >
                    Complete
                  </Button>
                ) : (
                  <Button
                    style={{ marginRight: '10px' }}
                    invert
                    textColor={colorToUse.AccentColorCode}
                    borderColor={colorToUse.AccentColorCode}
                    autoWidth
                    onClick={() => {
                      setIsResendFlow(true);
                      setShowSelfbookModal(true);
                      setResendSession(session);
                    }}
                  >
                    Resend Invite
                  </Button>
                )
              ) : (
                <Button
                  invert
                  autoWidth
                  textColor={colorToUse.AccentColorCode}
                  borderColor={colorToUse.AccentColorCode}
                  variant="grayedOut"
                  onClick={() => handleSessionComplete(contribution.id, session.id)}
                >
                  Unmark as complete
                </Button>
              )}
              {participant.firstName != undefined && (
                <LaunchSessionTime
                  user={user}
                  contribution={contribution}
                  sessionTime={session}
                  participantName={`${participant.firstName} ${participant.lastName}`}
                />
              )}
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={null}
                  sessionTime={session}
                  mobileView={mobileView}
                />
              )}
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', width: '100%' }}>
                {session?.id && !session.isCompleted && session.isPurchaseConfirmed && (
                  <Button
                    style={{ padding: '8px', backgroundColor: 'white', fontSize: '14px', minWidth: '8rem' }}
                    textColor={colorToUse.AccentColorCode}
                    borderColor={isRescheduleDisabled ? '#9b9b9b' : colorToUse.AccentColorCode}
                    onClick={() => {
                      setRescheduleModalState(true);
                      setRescheduleSession(true);
                      dispatch(
                        setEasyBookingData.setData({
                          selectedSlot: session,
                          timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
                            'h:mmA',
                          )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format(
                            'MMMM DD, yyyy',
                          )}`,
                          helpingMaterial: getItem('helpingMaterial'),
                          isBookedTime: true,
                        }),
                      );
                      dispatch(
                        setRescheduleFormerSessionDetail({
                          selectedSlot: session,
                          timeSlot: `${moment(session?.startTime).format('h:mmA')} - ${moment(session?.endTime).format(
                            'h:mmA',
                          )}, ${moment(session?.endTime).format('dddd')}, ${moment(session?.endTime).format(
                            'MMMM DD, yyyy',
                          )}`,
                          isBookedTime: true,
                          helpingMaterial: getItem('helpingMaterial'),
                        }),
                      );
                      dispatch(setRescheduleParticipantInfo(participant));
                    }}
                    disabled={isRescheduleDisabled}
                  >
                    Reschedule
                  </Button>
                )}
                {!session.isCompleted ? (
                  session.isPurchaseConfirmed ? (
                    <Button
                      style={{
                        padding: '8px',
                        backgroundColor: 'white',
                        marginLeft: '10px',
                        marginRight: '10px',
                        fontSize: '14px',
                        minWidth: '8rem',
                      }}
                      invert
                      textColor={colorToUse.AccentColorCode}
                      borderColor={colorToUse.AccentColorCode}
                      onClick={() => onShowPopup(contribution.id, session.id)}
                    >
                      Complete
                    </Button>
                  ) : (
                    <Button
                      style={{
                        padding: '8px',
                        backgroundColor: 'white',
                        marginLeft: '10px',
                        marginRight: '10px',
                        fontSize: '14px',
                        minWidth: '8rem',
                      }}
                      invert
                      textColor={colorToUse.AccentColorCode}
                      borderColor={colorToUse.AccentColorCode}
                      onClick={() => {
                        setIsResendFlow(true);
                        setShowSelfbookModal(true);
                        setResendSession(session);
                      }}
                    >
                      Resend Invite
                    </Button>
                  )
                ) : (
                  <Button
                    style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      marginRight: '10px',
                      fontSize: '14px',
                      minWidth: '8rem',
                    }}
                    invert
                    textColor={colorToUse.AccentColorCode}
                    borderColor={colorToUse.AccentColorCode}
                    variant="grayedOut"
                    onClick={() => handleSessionComplete(contribution.id, session.id)}
                  >
                    Unmark as complete
                  </Button>
                )}
                <Button
                  style={{ padding: '8px', backgroundColor: 'white', fontSize: '14px', minWidth: '8rem' }}
                  textColor={colorToUse.AccentColorCode}
                  borderColor={colorToUse.AccentColorCode}
                  onClick={() => {
                    setSessionIdForNotes(session.id);
                    handleOpenModal();
                  }}
                >
                  Open Notes
                </Button>
              </div>
              {participant.firstName != undefined && (
                <LaunchSessionTime
                  user={user}
                  contribution={contribution}
                  sessionTime={session}
                  participantName={`${participant.firstName} ${participant.lastName}`}
                  mobileView={mobileView}
                />
              )}
            </div>
          );
        }}
      />
      {console.info('view-100', events)}
      <CalendarContributionView
        dateToShow={dateToShow}
        calendarRef={calendarRef}
        events={events}
        onDrillDown={onCalendarDrillDownHandler}
        onSelectEvent={onCalendarEventSelectHandler}
        timeZoneId={contribution.timeZoneId}
        activeTemplate={activeTemplate || TemplateType.TemplateThree}
        selectedDate={selectedDate}
        showSelfbookModal={showSelfbookModal}
        setShowSelfbookModal={setShowSelfbookModal}
      />
      {isSlotModalOpen && (
        <SlotsModal
          isOpen={isSlotModalOpen}
          onClose={closeModalHandler}
          selectedDate={selectedDate}
          contributionId={id}
          isCoach
          title={title}
          serviceProviderName={serviceProviderName}
          duration={duration}
          onSubmit={onSelectEvent}
          isSelectable={rescheduleMode}
          clientName={`${clientFirstName} ${clientLastName}`}
        />
      )}
      {isRefuseModalOpen && (
        <RefuseModal
          isOpen={isRefuseModalOpen}
          onClose={() => setIsRefuseModalOpen(false)}
          onSubmit={() => setIsRefuseModalOpen(false)}
          message=""
        />
      )}
      {selectedSessionTime && (
        <LaunchSessionTimeModal
          user={user}
          contribution={contribution}
          sessionTime={selectedSessionTime}
          open={!!selectedSessionTime}
          onOpenChange={open => setSelectedSessionTime(session => (open ? session : null))}
        />
      )}
      {isCalendarRescheduleModalOpen && (
        <Modal
          isOpen={isCalendarRescheduleModalOpen}
          onCancel={handleCalendarRescheduleCancel}
          onSubmit={handleCalendarRescheduleSubmit}
          title="Confirm your new session time"
          hiddenCancel
          submitTitle="Confirm Reschedule"
        >
          <BodyText style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
            By clicking Confirm, you will be updating the session time with {clientFirstName} {clientLastName}. It’s
            your responsibility to confirm with {clientFirstName} {clientLastName} that this new time also works for
            them.
          </BodyText>
          <StyledTextField
            style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}
            placeholder="Optional. What is the reason for rescheduling? This information will be shared with your client"
            multiline
            rowsMax={6}
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
            textColor={isDarkThemeEnabled ? 'white' : 'black'}
          />
        </Modal>
      )}
      {showSelfbookModal && contribution?.activeTemplate != TemplateType.TemplateThree && (
        <ScheduleMeetingModal
          isCalendarFlow
          isVisible={true}
          closeModal={() => {
            setShowSelfbookModal(false);
          }}
          isCoach={isCoach}
          setRescheduleSession={() => {
            setRescheduleSession(true);
            setRescheduleModalState(true);
            setShowSelfbookModal(false);
          }}
          isResendFlow={isResendFlow}
          resendSession={resendSession}
          paymentInfo={contribution?.paymentInfo}
          setIsScheduleMeetingVisible={setShowSelfbookModal}
          selectedCalDate={selectedDate}
        />
      )}
      {calendarRescheduleModalStatus && (
        <Modal
          isOpen={calendarRescheduleModalStatus}
          onCancel={handleCalendarRescheduleStatusModalSubmit}
          onSubmit={handleCalendarRescheduleStatusModalSubmit}
          title="Reschedule status"
          hiddenCancel
          submitTitle="Ok"
        >
          <BodyText style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
            Your new session time with {clientFirstName} {clientLastName} on{' '}
            {moment(calendarRescheduleModalStatus?.start).format('LL [at] LT z')}
            {TIMEZONES[user?.timeZoneId]} is confirmed.
          </BodyText>
        </Modal>
      )}
      <Modal
        minWidth={mobileView ? '300px' : ''}
        height={'650px'}
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        PrimaryColor={btnColor}
        textColor={textColor}
        onSubmit={() => {
          setRescheduleModalState(false);
        }}
        title="Reschedule your session"
        noPadding={true}
        isBookingModal={true}
        disableFooter={true}
        CrossIconHide={!rescheduleSession}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule={true}
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal={true}
            isCoach={isCoach}
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal={true}
            handleCancel={() => setRescheduleModalState(false)}
          />
        )}
      </Modal>
      {modalOpened && (
        <NoteModal
          noteProps={{
            classId: sessionIdForNotes,
            contributionId: id,
          }}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}
      <Popup />
    </>
  );
};

CohealerOneToOneSessions.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    durations: PropTypes.arrayOf(PropTypes.number),
    serviceProviderName: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  contributionTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  rescheduleChosenSession: PropTypes.func.isRequired,
  getContributionTimes: PropTypes.func.isRequired,
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
});

const actions = {
  getCohealerContribution: fetchCohealerContribution,
  rescheduleChosenSession: rescheduleSession,
  getContributionTimes: fetchContributionTimes,
};

export default connect(mapStateToProps, actions)(CohealerOneToOneSessions);
