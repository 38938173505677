import React, { useState } from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CalendarSvg from '../icons/CalendarIcon';
import TimeSvg from '../icons/TimeIcon';
import { replaceBreakToBr, wrapText } from 'utils/utils';
import { useContribution } from 'pages/ContributionView/hooks';
import { GlobeIcon } from './SessionBookingThankyou';
import useAccount from 'hooks/useAccount';
import useScreenView from 'hooks/useScreenView';

const BookingSummary = ({
  coachAvatarUrl,
  serviceProviderName,
  duration,
  contributionTitle,
  purpose,
  accentColorCode,
  viewPurpose,
  primaryColorCode,
  rescheduleSession,
  isModal = false,
  setRescheduleSession,
}) => {
  const { user, currentRole } = useAccount();
  const { mobileView } = useScreenView();
  const activeContribution = useContribution();
  const easyBookingData = useSelector(state => state?.easyBooking);
  const formerBookingData = useSelector(state => state?.contributions.formerBookingData);
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 280;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const { isDarkModeEnabled } = activeContribution;
  const { timeZones } = useSelector(state => state?.timeZone);
  function getShortNameByCountryName(countryName) {
    const timezone = timeZones.find(tz => tz.countryName === countryName);
    return timezone ? timezone.shortName : 'PST';
  }
  return (
    <div className="user-details-side">
      <div className="user-details">
        <Avatar src={coachAvatarUrl} /> <p style={{ color: accentColorCode }}>{`${serviceProviderName}`}</p>
      </div>
      <div className="contribution-title">{contributionTitle}</div>
      <div className="duration-details">{`${duration} Minutes Session`}</div>

      {rescheduleSession &&
        easyBookingData?.selectedSlot?.id != formerBookingData?.selectedSlot?.id &&
        easyBookingData?.selectedSlot != null && (
          <div
            style={{
              fontFamily: 'Avenir',
              fontSize: '16px',
              fontWeight: 500,
              color: isDarkModeEnabled ? 'white' : '#000000',
              marginTop: '20px',
              marginBottom: '15px',
            }}
          >
            Rescheduled Time
          </div>
        )}
      {rescheduleSession &&
        easyBookingData?.selectedSlot?.id != formerBookingData?.selectedSlot?.id &&
        easyBookingData?.selectedSlot != null && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <CalendarSvg
                height={'20px'}
                width={'20px'}
                style={{ marginRight: '10px', marginTop: '4px' }}
                color={primaryColorCode}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: isDarkModeEnabled ? 'white' : '#000000',
                    fontFamily: 'Avenir',
                    fontSize: '16px',
                    fontWeight: 800,
                  }}
                >
                  {`${moment(easyBookingData?.selectedSlot?.start).format('h:mmA')} - ${moment(
                    easyBookingData?.selectedSlot?.end,
                  ).format('h:mmA')}, ${moment(easyBookingData?.selectedSlot?.end).format('dddd')},`}
                </div>
                <span
                  style={{
                    color: isDarkModeEnabled ? 'white' : '#000000',
                    fontFamily: 'Avenir',
                    fontSize: '16px',
                    fontWeight: 800,
                  }}
                >
                  {moment(easyBookingData?.selectedSlot?.end).format('MMMM DD, yyyy')}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <div style={{ position: 'relative', left: '-2px' }}>
                <GlobeIcon style={{ position: 'relative', left: '-2px' }} color={primaryColorCode} />
              </div>
              <div
                style={{
                  marginTop: '2px',
                  color: isDarkModeEnabled ? 'white' : '#000000',
                  fontFamily: 'Avenir',
                  fontSize: '16px',
                  fontWeight: 800,
                }}
              >
                {easyBookingData?.timeZone}
              </div>
            </div>
          </div>
        )}
      {rescheduleSession && (
        <div
          style={{
            fontFamily: 'Avenir',
            fontSize: '16px',
            fontWeight: 500,
            color: isDarkModeEnabled ? 'white' : '#000000',
            marginTop: '20px',
            marginBottom: '15px',
          }}
        >
          Former Time
        </div>
      )}
      {rescheduleSession && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <CalendarSvg
              height={'20px'}
              width={'20px'}
              style={{ marginRight: '10px', marginTop: '4px' }}
              color={primaryColorCode}
            />
            <div
              style={
                easyBookingData?.selectedSlot?.id != formerBookingData?.selectedSlot?.id &&
                easyBookingData?.selectedSlot != null
                  ? {
                      textDecoration: 'line-through',
                      color: isDarkModeEnabled ? 'white' : '#000000',
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                      fontWeight: 800,
                      width: mobileView ? '80%' : '100%',
                    }
                  : {
                      color: isDarkModeEnabled ? 'white' : '#000000',
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                      fontWeight: 800,
                      width: mobileView ? '80%' : '100%',
                    }
              }
              className="selected-date"
            >
              {formerBookingData.timeSlot}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <div style={{ position: 'relative', left: '-2px' }}>
              <GlobeIcon color={primaryColorCode} />
            </div>
            <div
              style={
                easyBookingData?.selectedSlot?.id != formerBookingData?.selectedSlot?.id &&
                easyBookingData?.selectedSlot != null
                  ? {
                      textDecoration: 'line-through',
                      marginTop: '2px',
                      color: isDarkModeEnabled ? 'white' : '#000000',
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                      fontWeight: 800,
                    }
                  : {
                      marginTop: '2px',
                      color: isDarkModeEnabled ? 'white' : '#000000',
                      fontFamily: 'Avenir',
                      fontSize: '16px',
                      fontWeight: 800,
                    }
              }
            >
              {formerBookingData?.timezone || user?.timeZoneId}
            </div>
          </div>
        </div>
      )}
      {rescheduleSession === false && (
        <div
          style={isModal ? { display: 'flex', marginTop: '15px' } : {}}
          className={isModal ? '' : 'selected-date-time'}
        >
          {isModal ? (
            <CalendarSvg
              height={'20px'}
              width={'20px'}
              style={{ marginRight: '10px', marginTop: '3px' }}
              color={primaryColorCode}
            />
          ) : (
            <CalendarSvg style={{ marginTop: '4px' }} color={primaryColorCode} />
          )}
          <div
            style={{
              color: isDarkModeEnabled ? 'white' : '#000000',
              fontFamily: 'Avenir',
              fontSize: '16px',
              fontWeight: 800,
              marginLeft: '10px',
            }}
            // className="selected-date"
          >
            {isModal
              ? easyBookingData?.selectedSlot
                ? `${moment(easyBookingData?.selectedSlot?.start).format('h:mmA')} - ${moment(
                    easyBookingData?.selectedSlot?.end,
                  ).format('h:mmA')}, ${moment(easyBookingData?.selectedSlot?.end).format('dddd')}, ${moment(
                    easyBookingData?.selectedDate,
                  ).format('MMMM D yyyy')}`
                : `${moment(easyBookingData?.selectedDate).format('MMMM D yyyy')}`
              : moment(easyBookingData?.selectedDate).format('MMM D')}
            {easyBookingData?.timeZone && easyBookingData?.selectedSlot && isModal && (
              <span
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  letterSpacing: '0px',
                  textAlign: 'center',
                  backgroundColor: '#AAABAD33',
                  color: isDarkModeEnabled ? 'white' : '#7B8180',
                  padding: '0 2px',
                  borderRadius: '5px',
                  marginLeft: '10px',
                }}
              >
                {getShortNameByCountryName(easyBookingData?.timeZone)}
              </span>
            )}
          </div>
          {easyBookingData?.selectedSlot && !isModal ? (
            <div style={{ marginLeft: '10px', display: 'flex' }}>
              <TimeSvg color={primaryColorCode} />
              <p>{moment(easyBookingData?.selectedSlot?.start).format('h:mmA')}</p>
              {easyBookingData?.timeZone && (
                <p
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    backgroundColor: '#AAABAD33',
                    color: isDarkModeEnabled ? 'white' : '#7B8180',
                    padding: '0 2px',
                    borderRadius: '5px',
                  }}
                >
                  {getShortNameByCountryName(easyBookingData?.timeZone)}
                </p>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {activeContribution.viewPurpose === false && purpose?.length > 0 && (
        <div style={{ color: isDarkModeEnabled ? 'white' : '#000000' }} className="easy-booking-purpose">
          {purpose.length > characterLimit ? (
            <div
              dangerouslySetInnerHTML={{
                __html: isExpanded
                  ? `${purpose} <span style="color:  ${accentColorCode}; cursor: pointer;"> Read Less</span>`
                  : `${purpose.substring(0, characterLimit)}${
                      purpose.length > characterLimit ? '' : ''
                    } <span style="color: ${accentColorCode}; cursor: pointer;"> ...Read More</span>`,
              }}
              onClick={toggleReadMore}
            ></div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: `${purpose}`,
              }}
              onClick={toggleReadMore}
            ></div>
          )}
        </div>
      )}
    </div>
  );
};

BookingSummary.propTypes = {
  serviceProviderName: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  coachAvatarUrl: PropTypes.func.isRequired,
  contributionTitle: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  accentColorCode: PropTypes.string.isRequired,
  primaryColorCode: PropTypes.string.isRequired,
};

export default BookingSummary;
