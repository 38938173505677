import React, { useEffect, useState } from 'react';
import { ContributionType } from 'helpers/constants';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as contributionActions from 'actions/contributions';
import { useAccount, useChangedProps, useEndVideoChat, useRouter, useShallowEqualSelector } from 'hooks';
import { isNil } from 'ramda';
import { stickHeader } from 'actions/header';
import useContribution from '../hooks/useContribution';
import CourseSessions from './CourseSessions';
import OneToOneSessions from './OneToOneSessions';
import SessionBookingThankyou from './OneToOneSessions/EasyBooking/components/SessionBookingThankyou';
import EasyBooking from './OneToOneSessions/EasyBooking/EasyBooking';
import { login, LOG_IN_FAILURE } from 'actions/user';
import { getItem, setItem } from 'services/localStorage.service';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import moment from 'moment';
import { setEasyBookingData } from 'actions/easyBooking';
import { isEmpty } from 'lodash';
import Modal from 'components/UI/Modal';
const ContributionSessionsContainer = props => {
  const { user, currentRole } = useAccount();
  const { history, query, location } = useRouter();
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const contribution = useContribution();
  const dispatch = useDispatch();
  const { isSelfSchedule } = useSelector(state => state.easyBookingThankYou);
  const { sessionTimeId } = useSelector(state => state.contributions);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const sessionId = urlParams.get('sessionId');
  const email = urlParams.get('email');
  const reschedulingSession = query.code;
  let fetchedBookedTime = getObjectFromBookedTime(sessionTimeId || sessionId);
  useEffect(() => {
    if (reschedulingSession === 'GuestUserReschedule' && sessionId) {
      dispatch(contributionActions.setRescheduleSessionTimeIdForGuestCheckout(sessionId));
    }
  }, [reschedulingSession]);

  useEffect(() => {
    if (reschedulingSession === 'GuestUserReschedule' && (sessionId || sessionTimeId)) {
      fetchedBookedTime = getObjectFromBookedTime(sessionTimeId || sessionId);
    }
  }, [sessionTimeId, sessionTimeId]);

  function getObjectFromBookedTime(targetId) {
    if (contribution.type === ContributionType.contributionOneToOne) {
      for (let item of contribution?.availabilityTimes) {
        const found = item.bookedTimes.find(time => time.id === targetId);
        if (found) {
          return found;
        }
      }
      return null;
    } else {
      return null;
    }
  }
  const handleAccountNotNeeded = async () => {
    const returnedLoginAction = await login(email.trim(), '', null, null, null, null, null, null, null, true)(dispatch);
    setRescheduleSession(true);
    dispatch(
      setEasyBookingThankYouData.setData({
        selectedSlot: fetchedBookedTime,
        timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mmA')} - ${moment(
          fetchedBookedTime?.endTime,
        ).format('h:mmA')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
          fetchedBookedTime?.endTime,
        ).format('MMMM DD, yyyy')}`,
        helpingMaterial: getItem('helpingMaterial'),
      }),
    );
    dispatch(
      setEasyBookingData.setData({
        selectedSlot: fetchedBookedTime,
        timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mmA')} - ${moment(
          fetchedBookedTime?.endTime,
        ).format('h:mmA')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
          fetchedBookedTime?.endTime,
        ).format('MMMM DD, yyyy')}`,
        helpingMaterial: getItem('helpingMaterial'),
      }),
    );
    dispatch(
      contributionActions.setRescheduleFormerSessionDetail({
        selectedSlot: fetchedBookedTime,
        timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mmA')} - ${moment(
          fetchedBookedTime?.endTime,
        ).format('h:mmA')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
          fetchedBookedTime?.endTime,
        ).format('MMMM DD, yyyy')}`,
        helpingMaterial: getItem('helpingMaterial'),
      }),
    );
    if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
      setItem('helpingMaterial', 'details');
      setItem('guestFirstName', 'FirstName');
      setItem('guestLastName', 'LastName');

      return true;
    }
  };

  const SESSIONS_MAPPING = {
    [ContributionType.contributionCourse]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionOneToOne]: (user, currentRole, contribution, props) => (
      <OneToOneSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionMembership]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionCommunity]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
  };

  // useEffect(() => {
  //   if (!isNil(query?.code)) {
  //     dispatch(contributionActions.setInviteCode(query?.code));
  //   }
  //   // else {
  //   //   dispatch(contributionActions.setInviteCode(null));
  //   // }
  // }, [dispatch, query?.code]);

  useEffect(() => {
    dispatch(stickHeader());
  }, []);

  useEffect(() => {
    if (reschedulingSession === 'GuestUserReschedule' && isEmpty(user)) {
      handleAccountNotNeeded();
      setRescheduleSession(true);
    } else if (reschedulingSession === 'GuestUserReschedule' && !isEmpty(user)) {
      setRescheduleSession(true);
      // setShowRescheduleModal(true);
      dispatch(
        setEasyBookingData.setData({
          selectedSlot: fetchedBookedTime,
          timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mmA')} - ${moment(
            fetchedBookedTime?.endTime,
          ).format('h:mmA')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
            fetchedBookedTime?.endTime,
          ).format('MMMM DD, yyyy')}`,
          helpingMaterial: getItem('helpingMaterial'),
        }),
      );
      dispatch(
        setEasyBookingThankYouData.setData({
          selectedSlot: fetchedBookedTime,
          timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mma')} - ${moment(
            fetchedBookedTime?.endTime,
          ).format('h:mma')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
            fetchedBookedTime?.endTime,
          ).format('MMMM DD, yyyy')}`,
          helpingMaterial: getItem('helpingMaterial'),
        }),
      );
      dispatch(
        contributionActions.setRescheduleFormerSessionDetail({
          selectedSlot: fetchedBookedTime,
          timeSlot: `${moment(fetchedBookedTime?.startTime).format('h:mmA')} - ${moment(
            fetchedBookedTime?.endTime,
          ).format('h:mmA')}, ${moment(fetchedBookedTime?.endTime).format('dddd')}, ${moment(
            fetchedBookedTime?.endTime,
          ).format('MMMM DD, yyyy')}`,
          helpingMaterial: getItem('helpingMaterial'),
        }),
      );
    }
  }, [user]);

  if (!isSelfSchedule && (user?.isSecondaryAccount || user?.isSecondaryExisting) && contribution.isPurchased) {
    if (rescheduleSession === false) {
      return (
        <SessionBookingThankyou setRescheduleSession={setRescheduleSession} rescheduleSession={rescheduleSession} />
      );
    } else {
      return (
        <EasyBooking
          clientPreviewMode={false}
          isSelfSchedule={!isSelfSchedule}
          rescheduleSession={rescheduleSession}
          setRescheduleSession={setRescheduleSession}
        />
      );
    }
  }

  return SESSIONS_MAPPING[contribution.type] ? (
    <div>
      <Modal
        isOpen={showRescheduleModal}
        onSubmit={() => {
          setShowRescheduleModal(false);
        }}
        title={'Reschedule your session'}
        disableFooter={true}
        onCancel={() => {
          setShowRescheduleModal(false);
        }}
        noPadding={true}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule={!isSelfSchedule}
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal={true}
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal={true}
            handleCancel={() => setShowRescheduleModal(false)}
          />
        )}
      </Modal>
      {SESSIONS_MAPPING[contribution.type](user, currentRole, contribution, props)}
    </div>
  ) : (
    <div>No sessions yet.</div>
  );
};

export default ContributionSessionsContainer;
