import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import VideocamIcon from '@material-ui/icons/Videocam';

const VideoButton = ({ handleClick, disabled, className }) => (
  <IconButton
    className={className}
    color="primary"
    aria-label="video picture"
    component="span"
    onClick={handleClick}
    disabled={disabled}
  >
    <VideocamIcon />
  </IconButton>
);

VideoButton.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

VideoButton.defaultProps = {
  handleClick: undefined,
  disabled: false,
};

export default VideoButton;
