import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { useAccount } from 'hooks';
import * as Yup from 'yup';

import { colors } from 'utils/styles';
import { PageSubtitleCoach } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionView.scss';
import { TIMEZONES } from 'constants.js';
import { UserRoles } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, determineDarkThemedColorToUse } from 'services/contributions.service';
import Button from 'components/FormUI/Button';
import { CircularProgress, Grid, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from '../../../../utils/utils';
import ScheduleMeetingModal from './ScheduleMeetingModal';
import TemplateThree from 'pages/ContributionView/Templates/TemplateThree';
import EasyBooking from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/EasyBooking';
import SessionBookingThankyou from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/components/SessionBookingThankyou';
import styled from 'styled-components';

const localizer = momentLocalizer(moment);

const allViews = [Views.MONTH];

const PageSubtitle = styled.h3`
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #282b2b;
  font-family: Brandon Text;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const CalendarContributionView = ({
  events,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  rescheduleMode,
  dateToShow,
  loadingTimes,
  timeZoneId,
  activeTemplate,
  selectedDate,
  showSelfbookModal,
  setShowSelfbookModal,
}) => {
  const contributionTimes = useSelector(state => state.contributions?.contributionTimes);
  const { currentRole } = useAccount();
  const isTemplateThree = activeTemplate === TemplateType.TemplateThree;
  const isCoach = currentRole === UserRoles.cohealer;
  const modifiedevents = events.filter(e => moment(e.start).isAfter(moment().add(24, 'h')));
  const SubTitle = isCoach || isTemplateThree ? PageSubtitleCoach : PageSubtitle;
  const contribution = useContribution();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [isScheduleMeetingVisible, setIsScheduleMeetingVisible] = useState(showSelfbookModal);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  let colorToUse = determineColorToUse(activeContribution);
  const { themedColor } = getThemedColors(contribution);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const currentDate = moment();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const closeModal = () => {
    setIsScheduleMeetingVisible(false);
    setShowSelfbookModal(false);
  };

  const onClickOpenModal = () => {
    setIsScheduleMeetingVisible(true);
  };
  const startTimes = contributionTimes?.map(item => item.startTime);
  const filteredDates = startTimes?.filter(date => {
    const dateObj = moment(date);
    return dateObj.isSameOrAfter(currentDate, 'day');
  });

  return (
    <div className="calendar-container" ref={calendarRef}>
      <Grid container justifyContent="space-between" spacing={1} style={{ padding: '5px' }} alignItems="center">
        <Grid item xs={8}>
          <SubTitle style={{ color: themedColor, display: 'flex', justifyContent: 'center' }}>
            Session Availability in {TIMEZONES[timeZoneId]}.{' '}
            {isCoach
              ? 'These are the session times that can be booked.'
              : 'Pick a time on the calendar below that works for you.'}
          </SubTitle>
        </Grid>
        {isCoach && activeContribution.activeTemplate != TemplateType.TemplateThree && (
          <Grid item xs={4} style={{ display: 'flex' }} justifyContent="flex-end">
            {contributionTimes === undefined ? (
              <CircularProgress style={{ marginLeft: '10px' }} size={20} />
            ) : activeContribution?.isAccountNeeded === true ? (
              <Button
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                onClick={() => onClickOpenModal()}
                style={{ textTransform: 'capitalize' }}
                variant="secondary"
                className="banner-container__button"
                autoWidth
              >
                Schedule Client
              </Button>
            ) : (
              <Tooltip
                title="To schedule a client, you must set up a 1:1 service with a complete booking experience selected on step one of creating a service."
                arrow
              >
                <div
                  style={{
                    backgroundColor: '#ededed',
                    color: '#9b9b9b',
                    fill: '#9b9b9b',
                    cursor: 'not-allowed',
                    border: 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    padding: '10px 16px',
                    borderRadius: '4px',
                    fontFamily: 'Avenir',
                    fontWeight: '900',
                    fontSize: '0.875rem',
                  }}
                  disabled={true}
                >
                  Schedule Client
                </div>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <BigCalendar
          className={`custom-calendar ${isDarkThemeEnabled ? 'dark-theme' : ''}`}
          style={{ color: themedColor }}
          localizer={localizer}
          events={rescheduleMode ? modifiedevents : events}
          views={allViews}
          defaultView={Views.MONTH}
          // date={dateToShow}
          step={30}
          showMultiDayTimes
          titleAccessor={event => `${event.title.substring(0, 15)}...`}
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onDrillDown={onDrillDown}
          eventPropGetter={event => {
            const newStyle = {
              backgroundColor: colors.darkOceanBlue,
            };

            if (event.isBooked) {
              newStyle.backgroundColor = colors.darkBlue;
            }

            return {
              className: '',
              style: newStyle,
            };
          }}
        />
      )}
      {isScheduleMeetingVisible === true && activeContribution.activeTemplate != TemplateType.TemplateThree && (
        <ScheduleMeetingModal
          isVisible={true}
          closeModal={closeModal}
          isCoach={isCoach}
          setRescheduleSession={() => {
            setRescheduleSession(true);
            setRescheduleModalState(true);
            closeModal();
          }}
          paymentInfo={activeContribution?.paymentInfo}
          setIsScheduleMeetingVisible={setIsScheduleMeetingVisible}
          selectedCalDate={moment(filteredDates[0])?.toDate()}
        />
      )}
      <Modal
        width={'850px'}
        minWidth={mobileView ? '100%' : '850px'}
        height={'650px'}
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        onSubmit={() => {
          setRescheduleModalState(false);
        }}
        title="Reschedule your session"
        noPadding
        isBookingModal
        disableFooter
        CrossIconHide={!rescheduleSession}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode
            isSelfSchedule
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal
            isCoach={isCoach}
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal
            handleCancel={() => setRescheduleModalState(false)}
          />
        )}
      </Modal>
    </div>
  );
};

CalendarContributionView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string,
};

CalendarContributionView.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
});

export default connect(mapStateToProps, null)(CalendarContributionView);
