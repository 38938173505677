import React, { useRef, useState, useMemo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useTheme, useMediaQuery, Typography, TextField } from '@material-ui/core';
import * as countryActions from 'actions/country';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ACCOUNT_FORM_FIELDS } from './PurchaseModal.constants';
import { RESTORE_PASS } from './PurchaseModal.constants';
import SignatureCanvas from 'react-signature-canvas';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';
import styled, { css } from 'styled-components';
import { colors } from 'utils/styles/styles';
import { TemplateType } from 'helpers/constants/templateType';
import { LabelText, PageSubtitle, PageTitleSecond } from 'components/UI/Text/TextStyles';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount, useClientPreview, useRouter, useDefaultTimezone } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import CreditCard from './CreditCard';
import CustomCreditCard from './CustomCreditCard';
import AccountForm from './AccountForm/AccountForm';
import CustomAccountForm from './CustomAccountForm/CustomAccountForm';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { PAYMENT_OPTIONS } from '../../../../constants.js';
import { ContributionType } from 'helpers/constants';
import ApplyCoupon from './ApplyCoupon';
import CustomApplyCoupon from './CustomApplyCoupon';
import { PURCHASE_MODAL_STEPS } from './PurchaseModal.constants';
import BookSingleSession from './CalendarBookSession';
import { darken } from 'polished';
import { determineColorToUse, getSingleContract, getThemedColors } from 'services/contributions.service';
import { useStyles } from '../PurchaseBlock/hooks/useStyles';
import { CleaningServices } from '@mui/icons-material';
import { PaymentOptions } from '../PaymentComponents';
import { dataURLtoFile, lightOrDark } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import EnrollmentForm from '../EnrollmentForm';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { fetchTimeZones } from 'actions/timeZone';
import timeZone from 'reducers/timeZone.reducer';
import { setEasyBookingData } from 'actions/easyBooking';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const PriceContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 2px 0px;
`;

const PriceValue = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  margin: 0px;
  font-size: 15px;
  padding: 2px;
  font-weight: 600;
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 92%;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const ApplyCouponContainer = styled.div`
  background-color: #fafafa;
  padding: 15px;
  // margin-top: 20px;
`;
const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;
const StyledHeading = styled.div`
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 25px;
  font-weight: 800;
  justify-content: center;
  display: flex;
`;

const PageSubtitleCustom = styled(PageSubtitle)`
  font-family: 'Brandon Text';
  font-style: normal;
  padding-top: 10px;
  // padding-bottom: 10px;
  margin-left: 10px;
`;

export const CustomPurchaseModalForm = ({
  submitTitle,
  loading,
  summary,
  values,
  isPackage,
  isMonthlySessionSubscription,
  perSessionStyled,
  sessionPackageStyled,
  monthlySessionSubscription,
  handleChange,
  setFieldValue,
  handleBlur,
  handleRedeem,
  errors,
  touched,
  error,
  setShowTerms,
  setSmsReminderCheckBox,
  smsReminderCheckBox,
  setshowPhoneNumberFields,
  showPhoneNumberFields,
  setloginStep,
  loginStep,
  setPhoneNumberField,
  phoneNumberField,
  step,
  setStepValue,
  paymentIntentCreated,
  sessionLifeTimeSeconds,
  onCheckoutSessionExpired,
  anyPaymentOptionEnabled,
  customWaiverTemplateName,
  isInviteToJoin,
  disableSubmitBtn,
  isCouponAvailable,
  formRef,
  typeOfPayment,
  customWaiverId,
  isCodeToJoin,
  setIsSigned,
  isSigned,
  stepVerifier,
  handleSubmit,
  setEnrollmentFormSubmitted,
  easyBooking = false,
  rescheduleSession = false,
  setRescheduleSession,
  packageSessionBooking = false,
  setShowModal = () => {},
  setRescheduleBookingmodal = () => {},
  isModal = false,
  showErrorMessage = false,
}) => {
  const [disableBtn, setDisableBtn] = useState(true);
  const canvasRef = useRef(null);
  const phoneInputRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const [waiverData, setWaiverData] = useState(null);
  const [isWaiverVisible, setIsWaiverVisible] = useState(false);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const { user } = useAccount();
  const classes = useStyles();
  const { serviceProviderName, customToS } = useContribution();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const { pathname, history } = useRouter();
  const { newThemedBackgroundColor, newThemedTextColor } = getThemedColors(contribution);
  const { code: freeEasyBookingCode = null } = useSelector(state => state?.easyBooking);
  const { states } = useSelector(state => state?.states);
  const [statesArray, setStatesArray] = useState([]);
  const [timeZoneSelected, settimeZoneSelected] = useState(null);
  const [showThreeFields, setshowThreeFields] = useState(false);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { countries } = useSelector(state => state?.country);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const easyBookingData = useSelector(state => state?.easyBooking);
  const easyBookingThankYouData = useSelector(state => state?.easyBookingThankYou);
  const perSession = !isMonthlySessionSubscription && !isPackage;
  const { fetchUserTimezone, fetchUserCountryCode } = useDefaultTimezone();
  const [phoneInputKey, setPhoneInputKey] = useState(0);

  const {
    newThemedBackgroundColor: themedBackgroundColor,
    newThemedTextColor: themedColor,
    newThemedCardColor,
  } = getThemedColors(contribution, false);
  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
    if (!countries || !countries.length) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
  }, [step, timeZones]);
  useEffect(() => {
    checkCountry();
  }, [states]);

  useEffect(() => {
    dispatch(
      setEasyBookingData.setData({ ...easyBookingData, isSelfSchedule: history.location.pathname.includes('s_') }),
    );
    dispatch(
      setEasyBookingThankYouData.setData({
        ...easyBookingData,
        isSelfSchedule: history.location.pathname.includes('s_'),
      }),
    );
  }, []);

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(formRef?.current?.values?.TimeZoneId, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
      setPhoneNumberField(user?.phoneNo);
    }
  }, [user]);

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [formRef?.current?.values?.TimeZoneId, timeZones, countries]);

  const applyFocusEventOnPhonInput = () => {
    if (phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return phoneInput;
    }
    return null;
  };

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = applyFocusEventOnPhonInput();
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, perSession, step]);

  useEffect(() => {
    setPhoneInputKey(phoneInputKey + 1);
  }, [defaultCountry]);

  useEffect(() => {
    applyFocusEventOnPhonInput();
  }, [phoneInputKey]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  const {
    activeTemplate,
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      membershipInfo,
    },
    type,
    title,
    defaultCurrency,
    defaultSymbol,
    isElectronicSignatureActive,
    isPurchased,
  } = contribution;
  const { clientPreviewMode } = useClientPreview();
  const OneToOne = type === ContributionType.contributionOneToOne;
  const TextColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const borderRad = activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const showDueLater = type !== ContributionType.contributionMembership;
  const singleSessionData = {
    summary,
    defaultCurrency,
    defaultSymbol,
  };
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
    color: themedColor,
    backgroundColor: themedBackgroundColor,
  };
  const classNames = useStyles(styledProps);
  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
      height: '53px',
    },
  }));

  const selectStyle = useSelectStyle();
  const setDisableValue = val => {
    setDisableBtn(val);
  };
  const isShowInput = (step, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [],
      joinLogin: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
      ],
      login: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, ACCOUNT_FORM_FIELDS.signature, RESTORE_PASS],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
      ],
    };
    return visible[step]?.includes(input);
  };
  const showPadForLoggedInClient = !isEmpty(user) && step === PURCHASE_MODAL_STEPS.loggedIn && !isPurchased;

  const disableJoinBtn =
    (disableBtn &&
      isElectronicSignatureActive &&
      customWaiverId === null &&
      isShowInput(step, ACCOUNT_FORM_FIELDS.signature)) ||
    (disableBtn && isElectronicSignatureActive && showPadForLoggedInClient && customWaiverId === null);

  const clearSign = () => {
    if (canvasRef?.current && formRef?.current) {
      canvasRef.current.clear();
      // setDisableBtn(true);
      setDisableValue(true);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.signature, null);
    }
  };

  // const agrementText = useMemo(
  //   () =>
  //     (isElectronicSignatureActive && showPadForLoggedInClient) ||
  //     (isElectronicSignatureActive && isShowInput(step, ACCOUNT_FORM_FIELDS.signature))
  //       ? 'signing and clicking'
  //       : 'clicking',
  //   [step, isElectronicSignatureActive, showPadForLoggedInClient],
  // );

  const hidePayment = contribution?.isPriceHidden && !isMonthlySessionSubscription;
  const isSessionPage = pathname?.includes('sessions');

  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };
    const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
    const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
    setStatesArray(filteredObjects);
    if (formRef.current && currentCountry) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
    }
    setTimeZoneArray(filteredTimezone);
    if (filteredTimezone.length === 1) {
      setshowThreeFields(false);
    } else {
      if (currentCountry?.id === user.countryId && filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        setshowThreeFields(true);
      }
    }
    if (!isEmpty(user)) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
    }
    if (formRef.current && timeZones?.length > 0) {
      const finalTimezone = await fetchUserTimezone(user?.timeZone, contribution?.timeZoneId, timeZones);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, finalTimezone);
    }
  };

  const isFreeContribution =
    (contribution.paymentInfo.paymentOptions.length === 1 &&
      contribution.paymentInfo.paymentOptions.includes('Free')) ||
    (contribution.paymentInfo.paymentOptions.length === 1 &&
      contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage')) ||
    (contribution.paymentInfo.paymentOptions.length === 2 &&
      contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage') &&
      contribution.paymentInfo.paymentOptions.includes('Free'));

  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card-form">
          {perSession || isCodeToJoin || ((freeEasyBookingCode || easyBooking) && isSessionPage) ? (
            <Grid item md={12} xs={12}>
              <BookSingleSession
                singleSessionData={singleSessionData}
                anyPaymentOptionEnabled={anyPaymentOptionEnabled}
                isInviteToJoin={isInviteToJoin}
                isCodeToJoin={isCodeToJoin}
                easyBooking={easyBooking}
                rescheduleSession={rescheduleSession}
                setRescheduleSession={setRescheduleSession}
                packageSessionBooking={packageSessionBooking}
                setShowModal={setShowModal}
                setRescheduleBookingmodal={setRescheduleBookingmodal}
                isModal={isModal}
              />
            </Grid>
          ) : (
            ((isFreeContribution === true && isInviteToJoin != null) ||
              (isFreeContribution === true && isCodeToJoin != null) ||
              isFreeContribution === false) && (
              <>
                <Grid container spacing={2}>
                  {!isInviteToJoin && (
                    <>
                      {/* Payment Summary */}

                      <Grid item md={12} xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '9px',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: colorToUse?.PrimaryColorCode,
                              paddingLeft: '15px',
                              paddingRight: '15px',
                              borderRadius: '5px',
                              fontSize: '18px',
                              color: 'white',
                              marginTop: '5px',
                              marginBottom: '15px',
                            }}
                          >
                            <PriceValue style={{ color: TextColor }}>
                              {isPackage
                                ? `${packageSessionNumbers} Sessions Package`
                                : `${monthlySessionSubscriptionInfo.duration} Months - ${
                                    monthlySessionSubscriptionInfo?.sessionCount *
                                    monthlySessionSubscriptionInfo?.duration
                                  } Sessions`}
                            </PriceValue>
                          </div>
                          {!hidePayment && (
                            <div
                              style={{
                                fontSize: '36px',
                                fontWeight: '700',
                                marginBottom: '15px',
                                color: newThemedTextColor,
                              }}
                            >
                              {defaultSymbol}
                              {formatMoney(summary?.price)}{' '}
                              <span style={{ fontWeight: '300', color: newThemedTextColor }}>
                                {' '}
                                {defaultCurrency.toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>

                        {!hidePayment && (
                          <div className={classes.priceSection}>
                            {summary &&
                              isPackage &&
                              sessionPackageStyled &&
                              paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && (
                                <>
                                  <PriceContainer backgroundColor={newThemedCardColor}>
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography style={{ lineHeight: 2.1 }}>
                                          {`${packageSessionNumbers} Sessions Package`}:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: 'bold', color: newThemedTextColor }}
                                        >
                                          {defaultSymbol}
                                          {formatMoney(summary?.price)}{' '}
                                          <span style={{ fontWeight: '300', color: newThemedTextColor }}>
                                            {' '}
                                            {defaultCurrency.toUpperCase()}
                                          </span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </PriceContainer>

                                  {/* {summary?.platformFee != 0 && (
                                    <PriceContainer backgroundColor={newThemedCardColor}>
                                      <Grid container justify="space-between">
                                        <Grid item>
                                          <Typography style={{ lineHeight: 2.1 }}>Processing Fee:</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            variant="h6"
                                            style={{ fontWeight: 'bold', color: newThemedTextColor }}
                                          >
                                            {defaultSymbol}
                                            {formatMoney(summary?.platformFee)}{' '}
                                            <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </PriceContainer>
                                  )}
                                  {summary?.platformFee != 0 && (
                                    <PriceContainer backgroundColor={newThemedCardColor}>
                                      <Grid container justify="space-between">
                                        <Grid item>
                                          <Typography style={{ lineHeight: 2.1 }}>Due Now:</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            variant="h6"
                                            style={{ fontWeight: 'bold', color: newThemedTextColor }}
                                          >
                                            {defaultSymbol}
                                            {formatMoney(summary?.dueNowWithCouponDiscountAmount)}{' '}
                                            <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </PriceContainer>
                                  )} */}
                                  {/* {summary.coupon?.discount && (
                          <PageSubtitle>
                            Discount: ${formatMoney(Math.min(summary.coupon?.discount, summary.dueNow))}
                          </PageSubtitle>
                        )} */}

                                  {/* <PriceContainer>
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Typography style={{ lineHeight: 2.1 }}>Due Now:</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                      {defaultSymbol}
                                      {formatMoney(summary?.dueNowWithCouponDiscountAmount)}{' '}
                                      <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </PriceContainer>

                              {showDueLater && (
                                <PriceContainer>
                                  <Grid container justify="space-between">
                                    <Grid item>
                                      <Typography style={{ lineHeight: 2.1 }}>Due Later:</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                        {defaultSymbol}
                                        {formatMoney(summary?.dueLater)}{' '}
                                        <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </PriceContainer>
                              )} */}
                                  {/* {paymentIntentCreated && sessionLifeTimeSeconds && (
                                <>
                                  <PriceContainer> */}
                                  {/* <Grid container justify="space-between">
                                    <Grid item>
                                      <Typography style={{ lineHeight: 2.1 }}>Checkout time left:</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Countdown
                                        date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                                        onComplete={() => {
                                          setSessionTimeExpired(true);
                                        }}
                                        renderer={props => (
                                          <>
                                            {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  </Grid> */}
                                  {/* </PriceContainer>
                                </>
                              )} */}
                                </>
                              )}
                            {summary &&
                              isMonthlySessionSubscription &&
                              monthlySessionSubscription &&
                              paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) && (
                                <>
                                  <PriceContainer backgroundColor={newThemedCardColor}>
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography style={{ lineHeight: 2.1 }}>Months Total:</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                          {defaultSymbol}
                                          {formatMoney(summary?.price - summary?.platformFee)}{' '}
                                          <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </PriceContainer>

                                  {summary?.platformFee != 0 && (
                                    <PriceContainer backgroundColor={newThemedCardColor}>
                                      <Grid container justify="space-between">
                                        <Grid item>
                                          <Typography style={{ lineHeight: 2.1 }}>Processing Fee:</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                            {defaultSymbol}
                                            {formatMoney(summary?.platformFee)}{' '}
                                            <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </PriceContainer>
                                  )}

                                  <PriceContainer backgroundColor={newThemedCardColor}>
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography style={{ lineHeight: 2.1 }}>Due Now:</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                          {defaultSymbol}
                                          {formatMoney(summary?.dueNowWithCouponDiscountAmount)}{' '}
                                          <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </PriceContainer>

                                  <PriceContainer backgroundColor={newThemedCardColor}>
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography style={{ lineHeight: 2.1 }}>Due Later:</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                          {defaultSymbol}
                                          {formatMoney(summary?.dueLater)}{' '}
                                          <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </PriceContainer>

                                  {paymentIntentCreated && sessionLifeTimeSeconds && (
                                    <PriceContainer backgroundColor={newThemedCardColor}>
                                      <Grid container justify="space-between">
                                        <Grid item>
                                          <Typography style={{ lineHeight: 2.1 }}>Checkout time left:</Typography>
                                        </Grid>
                                        <Grid item>
                                          <Countdown
                                            date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                                            onComplete={() => {
                                              setSessionTimeExpired(true);
                                            }}
                                            renderer={props => (
                                              <>
                                                {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                              </>
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    </PriceContainer>
                                  )}
                                </>
                              )}
                          </div>
                        )}
                      </Grid>

                      {isCouponAvailable && (
                        <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                          <CustomApplyCoupon
                            handleChange={handleChange}
                            activeTemplate={activeTemplate}
                            colorToUse={colorToUse}
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleRedeem={handleRedeem}
                            style={{ marginTop: hidePayment ? '10px' : 'inherit' }}
                          />
                        </Grid>
                      )}

                      {isMonthlySessionSubscription && monthlySessionSubscription && (
                        <Grid item md={12} xs={12} style={{ padding: '5px 55px' }}>
                          <CustomCreditCard
                            handleChange={handleChange}
                            activeTemplate={activeTemplate}
                            colorToUse={colorToUse}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setShowTerms={setShowTerms}
                          />
                          {error && <CommonErrorMessage message={error} />}
                        </Grid>
                      )}
                    </>
                  )}
                  {!isEmpty(user) && isInviteToJoin && (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{
                        padding: '20px 50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {isInviteToJoin != null && (
                        <div
                          style={{
                            backgroundColor: colorToUse?.PrimaryColorCode,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            borderRadius: '5px',
                            fontSize: '18px',
                            color: 'white',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <PriceValue style={{ color: TextColor }}>
                            {`${packageSessionNumbers} Sessions Package`}
                          </PriceValue>
                        </div>
                      )}
                      <StyledHeading>Book your session package </StyledHeading>
                      <PageSubtitleCustom style={{ color: themedColor }}>
                        This is a FREE Session Package. Please login or create a new account below in order to complete
                        your registration & to book your sessions!
                      </PageSubtitleCustom>

                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.join) &&
                        showPhoneNumberFields === true &&
                        !isEmpty(user) && (
                          <Grid container alignItems="flex-start" md={12} xs={12}>
                            <Checkbox
                              style={{ color: themedColor }}
                              id="create-contribution-agreement"
                              color="primary"
                              checked={smsReminderCheckBox}
                              onChange={() => {
                                if (smsReminderCheckBox === true) {
                                  setSmsReminderCheckBox(false);
                                } else {
                                  setSmsReminderCheckBox(true);
                                }
                                if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
                                  setPhoneNumberField(user?.phoneNo);
                                }
                              }}
                            />
                            <div
                              style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px', marginTop: '10px' }}
                            >
                              Receive SMS session reminders
                            </div>
                          </Grid>
                        )}
                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
                        isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.join) &&
                        smsReminderCheckBox === true &&
                        // perSession === true &&
                        showPhoneNumberFields === true &&
                        !isEmpty(user) && (
                          <Grid container direction="column" alignItems="flex-start" md={12} xs={12}>
                            <div style={{ width: '100%' }} ref={phoneInputRef}>
                              {defaultCountry && (
                                <PhoneInput
                                  key={coachPhoneNumberFocused + phoneInputKey}
                                  value={coachPhoneNumber}
                                  defaultCountry={defaultCountry}
                                  {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                  {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                  disableCountryGuess
                                  placeholder="Phone Number"
                                  onChange={phone => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                    setPhoneNumberField(phone);
                                    setCoachPhoneNumber(phone);
                                  }}
                                />
                              )}
                              {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                                <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                  {errors[ACCOUNT_FORM_FIELDS?.phone]}
                                </StyledError>
                              )}
                              {showErrorMessage && (
                                <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                  Please enter a valid phone number
                                </StyledError>
                              )}
                            </div>
                          </Grid>
                        )}
                    </Grid>
                  )}
                  {(isEmpty(user) || clientPreviewMode) && (
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                      item
                      md={12}
                      xs={12}
                    >
                      {isInviteToJoin != null && (
                        <div
                          style={{
                            backgroundColor: colorToUse?.PrimaryColorCode,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            borderRadius: '5px',
                            fontSize: '18px',
                            color: 'white',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                        >
                          <PriceValue style={{ color: TextColor }}>
                            {`${packageSessionNumbers} Sessions Package`}
                          </PriceValue>
                        </div>
                      )}
                      {isInviteToJoin && <StyledHeading>Book your session package</StyledHeading>}
                      {isInviteToJoin && (
                        <PageSubtitleCustom style={{ color: themedColor }}>
                          This is a FREE Session Package. Please login or create a new account below in order to
                          complete your registration & to book your sessions!
                        </PageSubtitleCustom>
                      )}
                      <CustomAccountForm
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        activeTemplate={activeTemplate}
                        isElectronicSignatureActive={isElectronicSignatureActive}
                        formRef={formRef}
                        setDisableValue={setDisableValue}
                        setSmsReminderCheckBox={setSmsReminderCheckBox}
                        smsReminderCheckBox={smsReminderCheckBox}
                        setshowPhoneNumberFields={setshowPhoneNumberFields}
                        showPhoneNumberFields={showPhoneNumberFields}
                        setloginStep={setloginStep}
                        loginStep={loginStep}
                        setPhoneNumberField={setPhoneNumberField}
                        phoneNumberField={phoneNumberField}
                        isMonthlySessionSubscription={isMonthlySessionSubscription}
                        colorToUse={colorToUse}
                        values={values}
                        errors={errors}
                        touched={touched}
                        step={step}
                        setStepValue={setStepValue}
                        isInviteToJoin={isInviteToJoin}
                        typeOfPayment={typeOfPayment}
                      />
                    </Grid>
                  )}
                  {(step === PURCHASE_MODAL_STEPS.enrollmentForm ||
                    step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) && (
                    <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                      <EnrollmentForm
                        enrollmentForm={contribution.enrollmentFormViewModel}
                        colorToUse={colorToUse}
                        contribution={contribution}
                        activeTemplate={activeTemplate}
                        style={{
                          padding: '0px 30px',
                          ...(pathname.includes('sessions') ? { backgroundColor: newThemedBackgroundColor } : {}),
                        }}
                        onSubmitSuccess={() => {
                          setEnrollmentFormSubmitted(true);
                          formRef.current.handleSubmit();
                        }}
                        onClose={() => setStepValue(PURCHASE_MODAL_STEPS.loggedIn)}
                        selectedTimezone={formRef?.current?.values?.TimeZoneId}
                        timeZones={timeZones}
                        countries={countries}
                      />
                    </Grid>
                  )}

                  {isElectronicSignatureActive && customWaiverId === null && showPadForLoggedInClient && (
                    <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                      <div style={{ position: 'relative' }}>
                        <SignatureCanvas
                          ref={canvasRef}
                          clearOnResize={false}
                          penColor="black"
                          canvasProps={{ height: '200px', className: classes.inputField }}
                          onEnd={() => {
                            // setDisableBtn(false);
                            setDisableValue(false);
                            if (canvasRef?.current) {
                              const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                              setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                            }
                          }}
                        />
                        <div
                          type="button"
                          onClick={clearSign}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '5px',
                            // color: colors.darkOceanBlue,
                            fontWeight: 'bold',
                          }}
                        >
                          Clear
                        </div>
                      </div>
                      <p
                        style={{
                          marginBottom: '0px',
                          fontSize: '12px',
                          marginLeft: '5px',
                          color: PrimaryColor,
                        }}
                      >
                        Sign Here To Join
                      </p>
                    </Grid>
                  )}
                </Grid>
                {!(step === PURCHASE_MODAL_STEPS.enrollmentForm || step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) &&
                  step !== PURCHASE_MODAL_STEPS.join &&
                  step !== PURCHASE_MODAL_STEPS.init && (
                    // !isMonthlySessionSubscription &&
                    // !isPackage &&
                    <>
                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.join) &&
                        showPhoneNumberFields === true &&
                        !isEmpty(user) && (
                          <Grid
                            item
                            alignItems="flex-start"
                            md={12}
                            xs={12}
                            style={{
                              padding: '5px 50px',
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              style={{ color: themedColor }}
                              id="create-contribution-agreement"
                              color="primary"
                              checked={smsReminderCheckBox}
                              onChange={() => {
                                if (smsReminderCheckBox === true) {
                                  setSmsReminderCheckBox(false);
                                } else {
                                  setSmsReminderCheckBox(true);
                                }
                              }}
                            />
                            <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                              Receive SMS session reminders
                            </div>
                          </Grid>
                        )}
                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
                        isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.join) &&
                        smsReminderCheckBox === true &&
                        // perSession === true &&
                        isInviteToJoin === null &&
                        showPhoneNumberFields === true &&
                        !isEmpty(user) && (
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            md={12}
                            xs={12}
                            style={{ padding: '5px 50px' }}
                          >
                            <div style={{ width: '100%' }} ref={phoneInputRef}>
                              {defaultCountry && (
                                <PhoneInput
                                  key={coachPhoneNumberFocused + phoneInputKey}
                                  value={coachPhoneNumber}
                                  defaultCountry={defaultCountry}
                                  {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                  {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                  disableCountryGuess
                                  placeholder="Phone Number"
                                  onChange={phone => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                    setPhoneNumberField(phone);
                                    setCoachPhoneNumber(phone);
                                  }}
                                />
                              )}
                              {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                                <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                  {errors[ACCOUNT_FORM_FIELDS?.phone]}
                                </StyledError>
                              )}
                              {showErrorMessage === true && (
                                <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                  Please enter a valid phone number
                                </StyledError>
                              )}
                            </div>
                          </Grid>
                        )}
                      <Grid style={{ padding: '0px 50px', marginTop: !isEmpty(user) ? '10px' : '' }} item>
                        {customToS ? (
                          isElectronicSignatureActive ? (
                            <Typography variant="caption">
                              By signing and clicking reserve,I agree to the{' '}
                              <StyledLink
                                href="/"
                                color={PrimaryColor}
                                onClick={e => {
                                  e.preventDefault();
                                  setShowTerms(true);
                                }}
                              >
                                Terms and Conditions
                              </StyledLink>
                              , I also agree to {serviceProviderName}
                              {"'"}s{' '}
                              <StyledLink color={PrimaryColor} href={customToS}>
                                Terms and Conditions
                              </StyledLink>
                              , and I'm at least 18 years old.
                            </Typography>
                          ) : (
                            <Typography variant="caption">
                              By clicking reserve,I agree to the{' '}
                              <StyledLink
                                href="/"
                                color={PrimaryColor}
                                onClick={e => {
                                  e.preventDefault();
                                  setShowTerms(true);
                                }}
                              >
                                Terms and Conditions
                              </StyledLink>
                              , I also agree to {serviceProviderName}
                              {"'"}s{' '}
                              <StyledLink color={PrimaryColor} href={customToS}>
                                Terms and Conditions
                              </StyledLink>
                              , and I'm at least 18 years old.
                            </Typography>
                          )
                        ) : (
                          <>
                            <Typography variant="caption">
                              {isElectronicSignatureActive ? 'By signing and clicking reserve' : 'By clicking reserve'},
                              I agree to the{' '}
                              <StyledLink
                                href="/"
                                color={PrimaryColor}
                                onClick={e => {
                                  e.preventDefault();
                                  setShowTerms(true);
                                }}
                              >
                                Terms and Conditions
                              </StyledLink>
                              {contribution?.customWaiverId && (
                                <>
                                  {' '}
                                  and {serviceProviderName}'s{' '}
                                  <StyledDiv color={PrimaryColor}>
                                    {contribution?.customWaiverTemplateName?.trim()}
                                  </StyledDiv>
                                </>
                              )}
                              , and I'm at least 18 years old.
                            </Typography>
                            <br />
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                {!(
                  step === PURCHASE_MODAL_STEPS.enrollmentForm || step === PURCHASE_MODAL_STEPS.joinEnrollmentForm
                ) && (
                  <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                    {!isEmpty(user) && showThreeFields && (
                      <>
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          !isMonthlySessionSubscription && (
                            <Grid
                              container
                              item
                              direction="column"
                              alignItems="flex-start"
                              md={12}
                              xs={12}
                              style={{
                                padding: '5px 5px',
                                position: 'relative',
                              }}
                            >
                              <Typography variant="h6" style={{ fontSize: '20px', color: themedColor }}>
                                Where are you located?
                              </Typography>
                            </Grid>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          !isMonthlySessionSubscription && (
                            <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                              <Select
                                label="Country of Residence"
                                name={ACCOUNT_FORM_FIELDS.country}
                                fullWidth
                                required
                                native
                                labelId="country"
                                value={formRef?.current?.values?.countryId}
                                className={[classNames.select, classNames.themedTextField].join(' ')}
                                onChange={e => {
                                  const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                  const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                  const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                                  if (filteredObjects.length >= 1) {
                                    setStatesArray(filteredObjects);
                                    if (e.target.value != '60b8ddb57205057e7ce2b861') {
                                      setTimeZoneArray(filteredTimezone);
                                    }
                                  } else if (filteredObjects.length === 0) {
                                    setStatesArray(filteredObjects);
                                    if (filteredTimezone.length === 0) {
                                      settimeZoneSelected(null);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                      settimeZoneSelected(null);
                                      setTimeZoneArray(timeZones);
                                    } else if (
                                      filteredTimezone.length === 1 &&
                                      filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                    ) {
                                      setTimeZoneArray(filteredTimezone);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                      settimeZoneSelected(null);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                                    } else {
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                      settimeZoneSelected(null);
                                      setTimeZoneArray(filteredTimezone);
                                    }
                                  }
                                  if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                                    setTimeZoneArray(filteredTimezone);
                                    setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
                                    setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                  }
                                  setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                }}
                                disableUnderline
                                style={{
                                  padding: '15px',
                                  position: 'relative',
                                  backgroundColor: newThemedBackgroundColor,
                                  color: newThemedTextColor,
                                  borderRadius: '10px',
                                }}
                              >
                                <option aria-label="Time Zone" value={null}>
                                  Select Country
                                </option>
                                {countries?.length > 0 &&
                                  countries.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                              </Select>
                            </Grid>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                          statesArray.length > 1 &&
                          !isMonthlySessionSubscription && (
                            <Grid
                              style={{ marginTop: 10 }}
                              item
                              xs={12}
                              className={selectStyle.handleNewFormFieldsTimeZone}
                            >
                              <Select
                                label="State"
                                name={ACCOUNT_FORM_FIELDS.state}
                                fullWidth
                                required
                                native
                                labelId="stateCode"
                                style={{
                                  padding: '15px',
                                  position: 'relative',
                                  backgroundColor: newThemedBackgroundColor,
                                  color: newThemedTextColor,
                                  borderRadius: '10px',
                                }}
                                value={formRef?.current?.values?.stateCode}
                                className={[classNames.select, classNames.themedTextField].join(' ')}
                                onChange={e => {
                                  const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                                  const filteredObjects = timeZones.filter(obj =>
                                    obj.statesIds.includes(filteredState.id),
                                  );
                                  if (filteredObjects.length >= 1) {
                                    if (filteredObjects.length === 1) {
                                      setTimeZoneArray(filteredObjects);
                                      settimeZoneSelected(filteredObjects[0].countryName);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                    } else {
                                      settimeZoneSelected(filteredObjects[0].countryName);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                      setTimeZoneArray(filteredObjects);
                                    }
                                  } else {
                                    setTimeZoneArray(timeZones);
                                  }
                                  setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                                }}
                                disableUnderline
                              >
                                <option aria-label="Time Zone" value={null}>
                                  Select State
                                </option>
                                {statesArray?.length > 0 &&
                                  statesArray.map(timeZone => (
                                    <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                                  ))}
                              </Select>
                            </Grid>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          timeZoneArray?.length > 1 &&
                          formRef?.current?.values?.countryId?.length > 1 && (
                            <>
                              <Grid
                                style={{ marginTop: '10px' }}
                                item
                                xs={12}
                                className={selectStyle.handleNewFormFieldsTimeZone}
                              >
                                <Select
                                  required
                                  native
                                  labelId="timezones"
                                  className={[classNames.select, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                  onChange={e => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                    settimeZoneSelected(e.target.value);
                                    const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                    setColorForSelect(color);
                                  }}
                                  value={timeZoneSelected}
                                  onBlur={handleBlur}
                                  disableUnderline
                                  style={{
                                    padding: '15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                    borderRadius: '10px',
                                  }}
                                >
                                  <option aria-label="Time Zone" value={null}>
                                    Select Timezone
                                  </option>
                                  {timeZoneArray?.length > 0 &&
                                    timeZoneArray.map(timeZone => (
                                      <option
                                        value={timeZone.countryName}
                                        style={{ backgroundColor: newThemedBackgroundColor, color: newThemedTextColor }}
                                      >
                                        {timeZone.name}
                                      </option>
                                    ))}
                                </Select>
                                {errors &&
                                  errors[ACCOUNT_FORM_FIELDS.timeZoneId] &&
                                  touched[ACCOUNT_FORM_FIELDS.timeZoneId] && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      {errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                                    </StyledError>
                                  )}
                              </Grid>
                            </>
                          )}
                      </>
                    )}
                    {/* <button type="submit">{submitTitle}</button> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {isInviteToJoin != null && !isEmpty(user) ? (
                        <StyledButton
                          style={{ borderRadius: borderRad, color: TextColor, width: '100%' }}
                          backgroundColor={PrimaryColor}
                          marginTop
                          type="submit"
                          activeTemplate={activeTemplate}
                          disabled={disableSubmitBtn || disableJoinBtn || clientPreviewMode}
                          onClick={handleSubmit}
                        >
                          {submitTitle}
                        </StyledButton>
                      ) : (
                        <StyledButton
                          style={{ borderRadius: borderRad, color: TextColor, width: '100%' }}
                          backgroundColor={PrimaryColor}
                          marginTop
                          type="submit"
                          activeTemplate={activeTemplate}
                          disabled={disableSubmitBtn || disableJoinBtn || clientPreviewMode}
                        >
                          {submitTitle}
                        </StyledButton>
                      )}
                    </div>
                  </Grid>
                )}
              </>
            )
          )}
        </Form>
      </FormikScrollToError>

      {/* <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      /> */}
    </>
  );
};

CustomPurchaseModalForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  isPackage: PropTypes.bool.isRequired,
  isMonthlySessionSubscription: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRedeem: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  setShowTerms: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  paymentIntentCreated: PropTypes.string,
  sessionLifeTimeSeconds: PropTypes.number,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

CustomPurchaseModalForm.defaultProps = {
  summary: undefined,
  isInviteToJoin: null,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
