import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/styles';
import { Typography } from '@mui/material';
import { useTheme, useMediaQuery, Grid, makeStyles } from '@material-ui/core';
import { TemplateType } from 'helpers/constants/templateType';
import SearchAndFilter from 'pages/Conversations/SearchAndFilter';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { StyledCloseIcon } from 'components/App/GettingStarted/GettingStarted.styles';
import { lightOrDark } from 'utils/utils';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import './Modal.scss';
import { useContribution } from 'pages/ContributionView/hooks';
import useAccount from 'hooks/useAccount';
import { SESSIONS_TYPES } from 'pages/ContributionView/ContributionSessions/constants';
import sumBy from 'lodash/sumBy';
import range from 'lodash/range';

const CustomTagDisplay = ({ option, onDelete, isDarkThemeApplied, numOfTags }) => {
  const maxLength = 7; // Set the maximum length for the displayed text
  const displayText =
    option?.title?.length > maxLength
      ? option?.title?.substring(0, maxLength) + '...' // Truncate the text
      : option?.title;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
  };

  return (
    <Chip
      label={numOfTags > 1 ? displayText : option?.title}
      onDelete={null}
      style={isDarkThemeApplied ? chipStyle : {}}
    />
  );
};

const StyledModalTitle = styled.span`
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.19px;
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
`;

function CustomOption({ label, selected }) {
  return <Typography style={{ fontWeight: selected ? '600' : '500' }}>{label}</Typography>;
}

const StyledModalFooter = styled.div`
  // border-top: 1px solid #e7e7e7;
  padding: 10px 20px;

  & button + button {
    margin-left: ${props => (!props.help ? '10px' : '0px')};
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const NotifictaionIcon = styled.div`
  position: absolute;
  background-color: ${colors.goldenColor};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  right: -10px;
  top: -10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StyledModalHeader = styled.div`
  padding: 8px 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
`;
const FilterMenu = styled.div`
  width: 300px;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '#2D2F31' : '#ffffff')};
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 10;
`;
const StyledModalBody = styled.div`
  padding: 10px 20px;
  flex: 1;
  max-height: 70vh;
  /* overflow-y: scroll; */
  overflow: auto;

  :: -webkit-scrollbar {
    // width: 5px;
  }
  /* Track */
  :: -webkit-scrollbar - track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  :: -webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #215c73;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  :: -webkit-scrollbar-thumb: window-inactive {
    background: #215c73;
  }
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ isCoach }) => isCoach && '20px'};
  align-items: center;
  margin-top: ${({ mobileView }) => mobileView && '30px'};
  justify-content: ${({ mobileView }) => (mobileView ? 'space-evenly' : 'flex-start')};
  ${({ isCoach, isTemplateThree, mobileView }) =>
    mobileView &&
    (isTemplateThree || isCoach) &&
    `
   justify-content: space-around;
  `}
`;
const Option = styled.div`
  margin-right: 15px;

  ${({ isCoach, colorForSelected, textColorUnselected }) =>
    !isCoach
      ? `
  padding: 10px 16px;
  background-color: white;
  border-radius: 5px;
  color: ${colorForSelected};
  border:1px solid ${colorForSelected};
   `
      : ''}
  &:hover {
    cursor: pointer;
  }

  ${({ isSelected, colorForSelected, textColorSelected }) =>
    isSelected &&
    ` 
    margin-right: 15px;
    background: ${colorForSelected};
    border-radius: 5px;
    padding: 10px 16px;
    color: ${textColorSelected};
    text-decoration: none;
  `}

  ${({ mobileView }) =>
    mobileView &&
    ` 
    padding: 10px 5px;
    font-size:10px;
   
  `}
`;

const StyledOptionForCoach = styled.div`
  margin-right: ${({ mobileView }) => (mobileView ? '5px' : '15px')};
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  padding: ${({ mobileView }) => (mobileView ? '12px 12px' : ' 12px 15px')};
  border-radius: 5px;
  border: ${({ colorForSelected }) => `1px solid ${colorForSelected}`};
  color: ${({ colorForSelected }) => colorForSelected};
  &:hover {
    cursor: pointer;
  }
  ${({ isCoach }) =>
    !isCoach
      ? `
  padding: 10px;
  background-color: gray;
  border-radius: 5px;
  color: white;
   `
      : ''}
  ${({ isSelected, colorForSelected, textColor }) =>
    isSelected &&
    `
    background-color: ${colorForSelected};
    color: ${textColor};
  `}
`;

const OptionForCoach = styled.div`
  margin-right: 15px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
  ${({ isSelected, colorForSelected, textColor }) =>
    isSelected &&
    `
     font-weight: 800;
     text-decoration: underline;
    color: ${colorForSelected};
  `}
`;

const useStyles = makeStyles(theme => ({
  customAutocomplete: {
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      width: '0px',
      minWidth: '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
    },
  },
  popupIndicator: {
    color: 'white !important',
  },
  CustomIcon: {
    // '& .MuiButton-startIcon': {
    //   marginRight: '0px !important',
    // },
    // '& .css-1d6wzja-MuiButton-startIcon': {
    //   marginRight: '0px !important',
    // },
    // '& .MuiButton-startIcon .MuiButton-iconSizeMedium .css-1d6wzja-MuiButton-startIcon': {
    //   marginRight: '0px !important',
    // },
  },
}));

const TabSelector = React.memo(
  ({
    options,
    selected,
    onChange,
    tabColor,
    isCoach,
    activeTemplate,
    colorToUse,
    textColor,
    isDarkThemeEnabled,
    clients,
    filteredClients,
    setFilteredClients,
  }) => {
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [isPeopleFocused, setIsPeopleFocused] = useState(false);
    const [selectedClient, setSelectedClient] = useState([{ title: 'All', value: 'All' }]);
    const noneIsSelectedInUsers = selectedClient?.length == 1 && selectedClient[0].value === 'All';
    const contribution = useContribution();
    const { user } = useAccount();
    const { availabilityTimes, clientPackages } = contribution;
    const classNames = useStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const isTemplateThree = activeTemplate === TemplateType.TemplateThree;
    const textColorBadge = lightOrDark(colorToUse?.PrimaryColorCode);
    const [offset, setOffset] = useState(0);
    function areAllObjectsPresent(childArray, parentArray) {
      return childArray.every(childObj =>
        parentArray.some(parentObj => Object.entries(childObj).every(([key, value]) => parentObj[key] === value)),
      );
    }

    function removeCommonObjects(childArray, parentArray) {
      const filteredParentArray = parentArray.filter(
        parentObj =>
          !childArray.some(childObj => Object.entries(childObj).every(([key, value]) => parentObj[key] === value)),
      );

      return filteredParentArray;
    }
    function isKeyWithValuePresent(key, value, arrayOfObjects) {
      return arrayOfObjects.some(obj =>
        Object.entries(obj).some(([objKey, objValue]) => objKey === key && objValue === value),
      );
    }

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsFilterMenuOpen(false);
        // setOffset(scrollPosition);
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [offset]);
    const onFilterClick = () => {
      setIsFilterMenuOpen(!isFilterMenuOpen);
    };

    useEffect(() => {
      if (filteredClients?.length > 0) {
        setNotificationsCount(1);
      } else {
        setNotificationsCount(0);
      }
    }, [filteredClients]);

    const computeSessions = val => {
      let resultant = [];
      for (let time of availabilityTimes) {
        for (let booked of time.bookedTimes) {
          let shouldInclude = false;
          if (isCoach && booked.participantId) {
            shouldInclude = true;
          } else if (booked.participantId === user?.id && booked.isPurchaseConfirmed) {
            shouldInclude = true;
          }
          if (shouldInclude) {
            if (val === SESSIONS_TYPES.COMPLETED) {
              if (booked.isCompleted) shouldInclude = true;
              else shouldInclude = false;
            } else if (val === SESSIONS_TYPES.UPCOMING) {
              if (booked.isCompleted) shouldInclude = false;
              else shouldInclude = true;
            }
          }

          if (shouldInclude) {
            let temp = {
              ...booked,
              startTime: time.startTime,
              timeId: time.id,
            };
            resultant.push(temp);
          }
        }
      }
      if (clientPackages && val != SESSIONS_TYPES.COMPLETED) {
        for (let i = 0; i < range(Math.max(sumBy(clientPackages, 'freeSessionNumbers'), 0)).length; i++) {
          resultant.push({
            hasPackage: true,
            index: i,
          });
        }

        let filteredResultant = [];
        if (filteredClients?.length > 0) {
          filteredResultant = resultant?.filter(s => filteredClients.includes(s.participantId));
        } else {
          filteredResultant = resultant;
        }
        let prevSessionIndex = null;
        const outputArray = filteredResultant.map((item, index) => {
          if (item.sessionIndex !== undefined) {
            prevSessionIndex = item.sessionIndex;
            return { ...item, index: prevSessionIndex };
          } else {
            return { ...item, index: prevSessionIndex + 1 + index };
          }
        });

        return outputArray.length;
      }

      return resultant.length;
    };
    const color = isDarkThemeEnabled && mobileView ? 'white' : colorToUse?.AccentColorCode;

    return (
      <>
        <OptionsList isCoach={isCoach} mobileView={mobileView}>
          {options.map(option => {
            return isCoach || isTemplateThree ? (
              <>
                {mobileView ? (
                  <>
                    <OptionForCoach
                      key={option.value}
                      onClick={() => onChange(option.value)}
                      isSelected={selected === option.value}
                      colorForSelected={colorToUse?.AccentColorCode}
                      textColor={textColor}
                      isCoach={isCoach}
                    >
                      {option.title}
                    </OptionForCoach>
                  </>
                ) : (
                  <>
                    <StyledOptionForCoach
                      key={option.value}
                      onClick={() => onChange(option.value)}
                      isSelected={selected === option.value}
                      colorForSelected={colorToUse?.AccentColorCode}
                      textColor={textColor}
                      isCoach={isCoach}
                      mobileView={mobileView}
                    >
                      {option.title}
                    </StyledOptionForCoach>
                  </>
                )}
              </>
            ) : (
              <>
                <Option
                  style={{ fontFamily: 'Avenir', fontWeight: '600' }}
                  key={option.value}
                  onClick={() => onChange(option.value)}
                  isSelected={selected === option.value}
                  colorForSelected={colorToUse?.AccentColorCode}
                  textColorUnselected={colorToUse?.AccentColorCode}
                  textColorSelected={textColor}
                  isCoach={isCoach}
                  mobileView={mobileView}
                >
                  {option.title}
                  <span style={{ marginLeft: '5px' }}>{computeSessions(option.value)}</span>
                </Option>
              </>
            );
          })}
          {isCoach && (
            <div style={{ position: 'relative' }}>
              {/* {notificationsCount > 0 && (
                <NotifictaionIcon style={{ backgroundColor: colorToUse?.PrimaryColorCode, color: textColorBadge }}>
                  {notificationsCount}
                </NotifictaionIcon>
              )} */}
              {isFilterMenuOpen && (
                <div>
                  <FilterMenu className="dropdown" isDarkThemeEnabled={isDarkThemeEnabled}>
                    <StyledModalHeader className="dropdown">
                      <StyledModalTitle isDarkThemeEnabled={isDarkThemeEnabled}>Filter By People:</StyledModalTitle>
                      <StyledCloseIcon
                        onClick={() => {
                          if (filteredClients?.length > 0) {
                            let previouslySelectedClient = [];
                            for (let c of clients) {
                              if (filteredClients.includes(c.value)) {
                                previouslySelectedClient.push(c);
                              }
                            }
                            setSelectedClient(previouslySelectedClient);
                          } else {
                            setSelectedClient([{ title: 'All', value: 'All' }]);
                          }
                          setIsFilterMenuOpen(false);
                        }}
                      />
                    </StyledModalHeader>{' '}
                    <StyledModalBody className="filter-menu-body dropdown">
                      <Grid item sm={12} xs={12} className="dropdown">
                        {/* <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                          By People
                        </p> */}

                        <Autocomplete
                          multiple
                          limitTags={1}
                          clearIcon={e => console.log(e)}
                          error={false}
                          name="byPeople"
                          options={[{ title: 'All', value: 'All' }]?.concat(clients)}
                          disableCloseOnSelect
                          value={selectedClient}
                          getOptionLabel={item => item.title}
                          renderInput={params => (
                            <TextField
                              {...params}
                              onFocus={() => setIsPeopleFocused(true)}
                              onBlur={() => setIsPeopleFocused(false)}
                              style={isDarkThemeEnabled ? { border: '1px solid white', borderRadius: '4px' } : {}}
                            />
                          )}
                          sx={{ width: '100%' }}
                          onChange={(e, newValue) => {
                            let values = newValue;
                            if (values.length > 1 && values.some(e => e.value === 'All')) {
                              const firstElementValue = values[0].value;
                              const lastElementValue = values[values.length - 1].value;
                              if (firstElementValue === 'All') {
                                values = values.filter(val => val.value !== 'All');
                              } else if (lastElementValue === 'All') {
                                values = values.filter(val => val.value === 'All');
                              } else if (areAllObjectsPresent(values, selectedClient) === false) {
                                values = values;
                              } else if (areAllObjectsPresent(values, selectedClient)) {
                                const updatedArray = removeCommonObjects(selectedClient, values);
                                setSelectedClient(updatedArray);
                              }
                            }
                            // if (values.length === 0) {
                            //   setSelectedClient([{ title: 'All', value: 'All' }]);
                            // } else if (areAllObjectsPresent(values, selectedClient) === false) {
                            //   setSelectedClient(values);
                            // } else if (areAllObjectsPresent(values, selectedClient)) {
                            //   const updatedArray = removeCommonObjects(selectedClient, values);
                            //   setSelectedClient(updatedArray);
                            // }

                            if (values.length === 0) {
                              setSelectedClient([{ title: 'All', value: 'All' }]);
                            } else {
                              setSelectedClient(values);
                            }
                          }}
                          renderTags={(value, getTagProps) => {
                            const numOfTags = value.length;
                            const limitTags = 2;
                            return !isPeopleFocused ? (
                              <>
                                {value.slice(0, limitTags).map((option, index) => (
                                  <CustomTagDisplay
                                    key={option.title}
                                    option={option}
                                    numOfTags={numOfTags}
                                    {...getTagProps({ index })}
                                    isDarkThemeApplied={isDarkThemeEnabled}
                                  />
                                ))}
                                <div
                                  style={
                                    isDarkThemeEnabled ? { color: 'white', marginLeft: '2px' } : { marginLeft: '2px' }
                                  }
                                >
                                  {numOfTags > limitTags && ` +${numOfTags - limitTags}`}
                                </div>
                              </>
                            ) : null;
                          }}
                          className={classNames.customAutocomplete}
                          classes={{
                            popupIndicator: isDarkThemeEnabled ? classNames.popupIndicator : {},
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ borderBottom: '1px solid #e7e7e7' }}>
                              <CustomOption
                                label={option.title}
                                selected={selected || isKeyWithValuePresent('title', option.title, selectedClient)}
                              />
                            </li>
                          )}
                        />
                      </Grid>
                    </StyledModalBody>{' '}
                    <StyledModalFooter className="dropdown">
                      <StyledFooterContainer className="modal-footer-container dropdown">
                        <Button
                          style={
                            isDarkThemeEnabled
                              ? { color: 'white', border: '1.25px solid white' }
                              : { color: 'black', border: '1.25px solid black' }
                          }
                          className="filter-cancel-button"
                          onClick={() => {
                            setSelectedClient([{ title: 'All', value: 'All' }]);
                            setIsFilterMenuOpen(false);
                            setFilteredClients([]);
                          }}
                          variant="outlined"
                        >
                          Clear
                        </Button>
                        <Button
                          style={{ background: colorToUse?.PrimaryColorCode, color: 'white' }}
                          className="filter-submit-button"
                          onClick={() => {
                            setIsFilterMenuOpen(false);
                            if (selectedClient?.length === 1 && selectedClient[0].value === 'All') {
                              setFilteredClients([]);
                            } else {
                              const clientIds = selectedClient?.map(c => c.value) || [];
                              setFilteredClients(clientIds);
                            }
                          }}
                        >
                          Apply
                        </Button>
                      </StyledFooterContainer>
                    </StyledModalFooter>
                  </FilterMenu>
                </div>
              )}
              <div className={mobileView ? 'icon_class_button' : ''}>
                <Button
                  variant="outlined"
                  className="FilterBtn"
                  startIcon={
                    <TuneIcon
                      htmlColor={filteredClients?.length ? textColor : color}
                      fontSize={mobileView ? '10px' : '14px'}
                      className="TuneIcon"
                      sx={{
                        marginRight: '0px !important',
                      }}
                    />
                  }
                  classes={{
                    startIcon: classNames.CustomIcon,
                  }}
                  sx={{
                    color: `${filteredClients?.length > 0 ? textColor : color} !important`,
                    backgroundColor: filteredClients?.length > 0 ? colorToUse?.AccentColorCode : '',
                    border: `1px solid ${colorToUse?.AccentColorCode} !important`,
                    textTransform: 'none !important',
                    height: '47px',
                    fontWeight: '900',
                    borderColor: `${color} !important`,
                    fontFamily: 'Brandon Text',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '15px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    width: '100%',
                    '&:hover': {
                      borderColor: `${filteredClients?.length > 0 ? colorToUse?.AccentColorCode : color} !important`,
                      backgroundColor: filteredClients?.length > 0 ? colorToUse?.AccentColorCode : 'transparent',
                    },
                    fontFamily: 'Avenir',
                  }}
                  onClick={onFilterClick}
                >
                  {mobileView ? '' : 'Filter by Client'}
                </Button>
              </div>
            </div>
          )}
        </OptionsList>
      </>
    );
  },
);

TabSelector.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TabSelector.defaultProps = {};

export default TabSelector;
